import { PartesListResults } from '../components/partes-list/partes-list-results';

function PartesFull() {
  return (
    <>
      <PartesListResults heigth={`calc(100vh - 100px)`} fullScrean={true} />
    </>
  )
}

export default PartesFull;