export const columns_certificados_asistente = [
    { 
        "id": "certificado_numero",
        "numeric": false,
        "disablePadding": false,
        "label": "Certificado",
        "width": 10,
        "show": true,
        "placeHolder": "-",
        "type": "text",//text, number, date, select, none
        "search": null
    }, 
    {
        "id": "contrato[0].nombre",
        "numeric": false,
        "disablePadding": false,
        "label": "Contrato",
        "width": 160,
        "show": true,
        "placeHolder": "-",
        "type": "text",
        "search":null
    },
    {
        "id": "certificado_realizado_fecha",
        "numeric": false,
        "disablePadding": true,
        "label": "Fecha",
        "width": 190,
        "show": true,
        "placeHolder": "#",
        "type": "date",//text, number, date, select, none
        "search": null
    },
    {
        "id": "certificado_finalizado",
        "numeric": false,
        "disablePadding": true,
        "label": "Certificado Finalizado",
        "width": 10,
        "show": true,
        "placeHolder": "#",
        "type": "select",//text, number, date, select, none
        "search": null
    },
    {
        "id": "certificado_finalizado_fecha",
        "numeric": false,
        "disablePadding": true,
        "label": "Certificado Finalizado Fecha",
        "width": 130,
        "show": true,
        "placeHolder": "#",
        "type": "date_editable",//text, number, date, select, none
        "search": null
    },   
    { 
        "id": "hes",
        "numeric": false,
        "disablePadding": true,
        "label": "#HES",
        "width": 100,
        "show": true,
        "placeHolder": "-",
        "type": "text_editable",//text, number, date, select, none
        "search": null
    }, 
    {
        "id": "certificado_facturado",
        "numeric": false,
        "disablePadding": true,
        "label": "Certificado Facturado",
        "width": 10,
        "show": true,
        "placeHolder": "#",
        "type": "select",//text, number, date, select, none
        "search": null
    },
    {
        "id": "certificado_facturado_fecha",
        "numeric": false,
        "disablePadding": true,
        "label": "Certificado Facturado Fecha",
        "width": 130,
        "show": true,
        "placeHolder": "#",
        "type": "date_editable",//text, number, date, select, none
        "search": null
    },   
    { 
        "id": "factura_numero",
        "numeric": false,
        "disablePadding": true,
        "label": "Número Factura",
        "width": 100,
        "show": true,
        "placeHolder": "-",
        "type": "text_editable",//text, number, date, select, none
        "search": null
    }, 
   
]