import { Line } from 'react-chartjs-2';
import { Box, Card, Grid, IconButton,  Divider,  Typography } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardGetFactoresEconomicos,  dashboardValorPartesCertificados } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { colors_palette } from "../../utils/colors-palette";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'

Chart.register(...registerables);

export const ValorPartesCertificados = ({ filters, ...props }) => {
  const settings = segmentacionSettings("mensual")
  const [results, setResults] = useState([])

  //Obtiene la data del gráfico y la afecta por los factores económicos
  useEffect(() => {
    const getData = async () => {
      const res1 = await dashboardGetFactoresEconomicos();
      const res = await dashboardValorPartesCertificados({ filters: {...filters, segmentacion:"mensual"} })
      let items_certificados = res.data?.items_certificados?.map((x) => {
        let factor = res1?.data.filter((y) => y.date === x._id)
        let dolar_price = factor[0]?.dolar_price || 1
        return ({ _id: x._id, value: x.value / dolar_price })
      })
      let items_no_certificados = res.data?.items_no_certificados.map((x) => {
        let factor = res1?.data.filter((y) => y.date === x._id)
        let dolar_price = factor[0]?.dolar_price || 1
        return ({ _id: x._id, value: x.value / dolar_price })
      })
      setResults({ items_certificados, items_no_certificados })
    }
    getData()
  }, [filters])



  const data = {
    datasets: [
      {
        label: "Ensayos realizados",
        data: results?.items_no_certificados?.map((dato) => {
          return ({
            date: dato._id,
            value: dato.value
          })
        }),
        borderColor: colors_palette[7]
      },
      {
        label: "Ensayos certificados",
        data: results?.items_certificados?.map((dato) => {
          return ({
            date: dato._id,
            value: dato.value
          })
        }),
        borderColor: colors_palette[2]
      }
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        fontSize: 2,
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
      /*       title: {
              display: true,
              text: 'Valor de inspecciones realizadas y certificadas por mes'
            } */
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value'
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          unitStepSize: 1,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },

    },
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          //justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.3em 1.4em" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '85%' }}>
            <Typography
              sx={{ m: 1 }}
              variant="h6"
              style={{ fontSize: "1em" }}
            >
              {`Valor de inspecciones realizadas y certificadas [USD]`}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: 1 }}>
             {/*  <Tooltip title={"Fijar precio USD promedio mensual"}>
                <IconButton
                  //color=""
                  variant="contained"
                  size='small'
                  onClick={() => { handleOpenChange() }}
                >
                  <LocalAtmIcon fontSize='inerhit' />
                </IconButton>
              </Tooltip> */}
              <IconButton
                //color=""
                variant="contained"
                size='small'
                onClick={() => { genericoXLS({ data: data.datasets }) }}
              >
                <FileDownloadIcon fontSize='inerhit' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >
        <Line
          data={data}
          options={options}
        />
      </Box>
{/*       <FactoresEconomicos
        handleOpenChange={handleOpenChange}
        handleReload={handleReload}
        open={open} /> */}
    </Card>
  );
};
