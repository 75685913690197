import { Box, Container } from '@mui/material';
import { PartesListResults } from '../components/partes-list/partes-list-results';
import { DashboardLayout } from '../layout/layout';

function PartesList() {
  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 0
          }}
        >
          <Container maxWidth='xl'  >
            <PartesListResults heigth={`calc(100vh - 180px)`} fullScrean={false}/>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default PartesList;