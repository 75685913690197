import { Avatar, Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import LimitTags from '../../../styled-components/styled-autocomplete-limittags';
import LimitTagsSimple from '../../../styled-components/styled-autocomplete-limittagssimple';
import { contractGetAreas } from '../../../services/contracts';
import { userGetNames } from '../../../services/users';


export const Filtros = ({handleFiltersChange, filters, ...props }) => {

  return (
    <Card {...props} style={{ height: "100%" }}>
      <CardContent>
        <Stack direction="column" spacing={1}>
          <LimitTagsSimple description={"Area"} handleFiltersChange={handleFiltersChange} id="area" getFunction={contractGetAreas}  />
          <LimitTags description={"Operadores"} handleFiltersChange={handleFiltersChange} id="operadores" getFunction={userGetNames}   />
        </Stack>
      </CardContent>
    </Card>
  )
}
