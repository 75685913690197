import { Card, CardContent, Typography, Grid, Box, Chip, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardGetKPIObjetivos, dashboardKPIEficienciaGrupal } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";

function KpiGrupalesTabla({ filters, handleReload, reload, ...props }) {
  const [efficiency, setEfficiency] = useState(0)
  const [productivity, setProductivity] = useState(0)
  const [objetivos, setObjetivos] = useState({ eficiencia: 0, productividad: 0 })
  let efficiencyColor, efficiencyLabel, productivityColor, productivityLabel;

  useEffect(() => {
    async function getList() {
      try {
        const res = await dashboardKPIEficienciaGrupal({ filters: filters })
        const res1 = await dashboardGetKPIObjetivos()
        const ultimoMes = res.data.at(0)
        setEfficiency(ultimoMes?.eficiencia ? ultimoMes.eficiencia.toFixed(2) : 'S/D')
        setProductivity(ultimoMes?.productividad ? ultimoMes.productividad.toFixed(2) : 'S/D')
        setObjetivos(res1.data)
      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters, reload])

  if (efficiency > Number(objetivos.eficiencia)) {
    efficiencyColor = 'success';
    efficiencyLabel = 'Excelente';
  } else if (efficiency > Number(objetivos.eficiencia) * 0.8) {
    efficiencyColor = 'warning';
    efficiencyLabel = 'Buena';
  } else {
    efficiencyColor = 'error';
    efficiencyLabel = 'Mala';
  }

  if (productivity > Number(objetivos.productividad)) {
    productivityColor = 'success';
    productivityLabel = 'Excelente';
  } else if (productivity > Number(objetivos.productividad) * 0.8) {
    productivityColor = 'warning';
    productivityLabel = 'Buena';
  } else {
    productivityColor = 'error';
    productivityLabel = 'Mala';
  }

  return (
    <Card style={{ height: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          //justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.7em 1.4em" }}
      >

        <Typography
          sx={{ m: 1 }}
          variant="h6"
          style={{ fontSize: "1em" }}
        >
          Último mes
        </Typography>

      </Box>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} md={12}>
            <Box sx={{ textAlign: 'center' }} style={{ paddingBottom: '20px' }}>
            <Typography variant="subtitle1">
                Eficiencia
              </Typography>
              <Typography variant="h4" component="h2">
                {efficiency}
              </Typography>
             
              <Chip label={efficiencyLabel} color={efficiencyColor} />
            </Box>
          </Grid>
          <Grid item xs={6} md={12}>
            <Box sx={{ textAlign: 'center' }} style={{ paddingBottom: '20px' }}>
            <Typography variant="subtitle1" style={{ paddingTop: '0px' }}>
                Productividad
              </Typography>
              <Typography variant="h4" component="h2" style={{ paddingBottom: '0px' }}>
                {productivity}%
              </Typography>
              
              <Chip label={productivityLabel} color={productivityColor} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default KpiGrupalesTabla