import Chip from '@mui/material/Chip';
import { Tooltip, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { allowedRoles} from "../utils/list";


//Alerts y Notifications
import Notification from './alerts/notification';
import NotificationDialog from './alerts/notification-dialog';
import ConfirmDialog from './alerts/confirm-dialog';

//icons 
import EventIcon from '@mui/icons-material/Event';
import ArrowCircleBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleForwardIcon from '@mui/icons-material/ArrowForward';


export default function StyledChipUpdateMany({ handleReload, data, selected, handleConfirmDialogChange, handleNotifyChange, selectedToEmpty, rol, edit, ...props }) {
    const [show, setShow] = useState(false);
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [notificationDialog, setNotificationDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [estados, setEstados] = useState({ pre: "", post: "" })
    const [disable, setDisable] = useState({ pre: false, post: false })

    function handleRequestToChange({ field, value, selected }) {
        setConfirmDialog({
            isOpen: true,
            title: `¿Desea cambiar el estado a ${!value ? "NO" : "SI"}?`,
            subTitle: value ? `Al confirmar, se eliminará la fecha registrada y no podrá volver atrás` :
                `Al confirmar, se guardará la fecha del dia de hoy`,
            onConfirm: () => { handleEdit({ field, value, selected }) },
            icon: <EventIcon fontSize='inherit' color={value ? "success" : "error"} />
        })
    }


    async function handleEdit({ field, value, selected }) {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        try {
            await edit({ data: { [field]: value }, id: selected })
            setNotify({
                isOpen: true,
                message: `El estado se modificó correctamente a ${value ? "NO" : "SI"}`,
                type: value ? "error" : "success"
            })
            handleReload()
            selectedToEmpty()
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        setShow(!data.some((item) => {
            return (!(
                item.trabajo_terminado === data[0]?.trabajo_terminado &&
                item.informe_realizado === data[0]?.informe_realizado &&
                item.informe_revisado === data[0]?.informe_revisado &&
                item.remito_realizado === data[0]?.remito_realizado &&
                item.remito_revisado === data[0]?.remito_revisado &&
                item.remito_entregado === data[0]?.remito_entregado &&
                item.remito_firmado === data[0]?.remito_firmado &&
                item.certificado_realizado === data[0]?.certificado_realizado &&
                item.certificado_finalizado === data[0]?.certificado_finalizado
            ))
        }))
        //Verifica que todos los partes seleccionados tengan el mismo estado
        let estado = { pre: "ninguno", post: "ninguno" }
        switch ([
            !(data[0]?.trabajo_terminado === undefined) ? data[0]?.trabajo_terminado : true,
            !(data[0]?.informe_realizado === undefined) ? data[0]?.informe_realizado : true,
            !(data[0]?.informe_revisado === undefined) ? data[0]?.informe_revisado : true,
            !(data[0]?.remito_realizado === undefined) ? data[0]?.remito_realizado : true,
            !(data[0]?.remito_revisado === undefined) ? data[0]?.remito_revisado : true,
            !(data[0]?.remito_entregado === undefined) ? data[0]?.remito_entregado : true,
            !(data[0]?.remito_firmado === undefined) ? data[0]?.remito_firmado : true,
            !(data[0]?.certificado_realizado === undefined) ? data[0]?.certificado_realizado : true,
            !(data[0]?.certificado_finalizado === undefined) ? data[0]?.certificado_finalizado : true,
        ].toString()) {
            case [false, false, false, false, false, false, false, false, false].toString():
                estado = { pre: "ninguno", post: "trabajo_terminado" }
                break;
            case [true, false, false, false, false, false, false, false, false].toString():
                estado = { pre: "trabajo_terminado", post: "informe_realizado" }
                break;
            case [true, true, false, false, false, false, false, false, false].toString():
                estado = { pre: "informe_realizado", post: "informe_revisado" }
                break;
            case [true, true, true, false, false, false, false, false, false].toString():
                estado = { pre: "informe_revisado", post: "remito_realizado" }
                break;
            case [true, true, true, true, false, false, false, false, false].toString():
                estado = { pre: "remito_realizado", post: "remito_revisado" }
                break;
            case [true, true, true, true, true, false, false, false, false].toString():
                estado = { pre: "remito_revisado", post: "remito_entregado" }
                break;
            case [true, true, true, true, true, true, false, false, false].toString():
                estado = { pre: "remito_entregado", post: "remito_firmado" }
                break;
            case [true, true, true, true, true, true, true, false, false].toString():
                estado = { pre: "remito_firmado", post: "certificado_realizado" }
                break;
            case [true, true, true, true, true, true, true, true, false].toString():
                estado = { pre: "certificado_realizado", post: "certificado_finalizado" }
                break;
            case [true, true, true, true, true, true, true, true, true].toString():
                estado = { pre: "certificado_finalizado", post: "ninguno" }
                break;
            default:
                break;
        }
        setEstados(estado)

        //Dependiento el rol del usuario, se habilita o no el 
        const field_allowed = allowedRoles.filter((item) => item.rol === rol)[0]?.fields_allowed
        if (field_allowed?.includes(estado.pre)) {
            setDisable({ ...disable, pre: false })
            //estado.pre === 'remito_realizado' && setDisable({ post: false, pre: true })
            //estado.pre === 'certificado_realizado' && setDisable({ post: false, pre: true })
            //estado.pre === 'certificado_finalizado' && setDisable({ post: true, pre: true })
        } else {
            setDisable({ ...disable, pre: true })
        }

        if (field_allowed?.includes(estado.post)) {
            setDisable({ ...disable, post: false })
            estado.post === 'trabajo_terminado' && setDisable({ pre: true, post: false })
            estado.post === 'remito_realizado' && setDisable({ pre: false, post: true })
            estado.post === 'remito_revisado' && setDisable({ pre: true, post: false })
            estado.post === 'certificado_realizado' && setDisable({ pre: false, post: true })
            estado.post === 'certificado_finalizado' && setDisable({ pre: true, post: false })
            estado.post === 'ninguno' && setDisable({ pre: true, post: false })
            //estado.post === 'certificado_realizado' && setDisable({ pre: false, post: true })
            //estado.post === 'certificado_finalizado' && setDisable({ pre: true, post: false })
        } else {
            setDisable({ ...disable, post: true })
        }
    }, [data])

    return (
        <>
            {show &&
                <Stack direction="row" spacing={1}>

                    {!disable.pre &&
                        <Tooltip title={`Cambiar estado anterior a "NO"`}>
                            <Chip
                                sx={{ width: "55px" }}
                                size="small"
                                label={"NO"}
                                color={"error"}
                                onClick={() => {

                                    //actualizarEstado({field:estados.pre, value: false, selected: selected})
                                    handleRequestToChange({ field: estados.pre, value: false, selected: selected })
                                }}
                                // variant={"outlined"}
                                clickable={true}
                                icon={<ArrowCircleBackIcon />}
                                variant={disable.pre ? 'outlined' : "filled"}
                            />
                        </Tooltip>
                    }

                    {!disable.post &&
                        <Tooltip title={`Cambiar siguiente estado a "SI"`}>
                            <Chip
                                sx={{ width: "55px" }}
                                size="small"
                                label={"SI"}
                                color={"success"}
                                onClick={() => {
                                    //actualizarEstado({field:estados.post, value: true, selected: selected})
                                    handleRequestToChange({ field: estados.post, value: true, selected: selected })
                                }}
                                //variant={"outlined"}
                                clickable={true}
                                icon={<ArrowCircleForwardIcon />}
                                variant={disable.post ? 'outlined' : "filled"}
                            />
                        </Tooltip>}
                    <Notification
                        notify={notify}
                        setNotify={setNotify} />
                    <ConfirmDialog
                        confirmDialog={confirmDialog}
                        setConfirmDialog={setConfirmDialog} />
                    <NotificationDialog
                        confirmDialog={notificationDialog}
                        setConfirmDialog={setNotificationDialog} />
                </Stack>
            }
        </>
    );
}
StyledChipUpdateMany.defaultProps = {
    selectedToEmpty: () => { },
    edit: () => { }
}