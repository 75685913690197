import { format } from 'date-fns';
import {
  Box,
  Paper,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Skeleton
} from '@mui/material';
import { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { parteGetRestricted, parteEdit } from '../../../../services/partes';
import StyledChipUpdate from '../../../../styled-components/styled-chip-update';



export const PartesTable = ({ handleReload, reload, user, filters, ...props }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const partes = data?.docs || []

  //Pagination states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const rowsCount = data.totalDocs || 6
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true)
    handleReload()
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleReload()
    setLoading(true)
  };

  useEffect(() => {

    async function getList() {
      try {
        const res = await parteGetRestricted(page, rowsPerPage, 'asc', "Id", { 'informe_realizado': true, 'informe_revisado': false, area: filters.area, operadores: filters.operadores })
        setData(res.data)
        setLoading(false)

      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [reload, filters])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = partes.length < rowsPerPage ? rowsPerPage - partes.length : 0;

  return (
    <Card style={{ height: "585px" }} {...props}>
      <CardHeader title="Informes para Corrección" />
      <Paper sx={{ overflowX: "auto", width: "100%", height: '445px' }}>
        <Box sx={{ minWidth: 500 }}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  Número de Orden
                </TableCell>
                <TableCell>
                  TAG Equipo
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Fecha
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Informe Realizado
                </TableCell>
                <TableCell>
                  Informe Revisado
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                partes.map((parte) => (
                  <TableRow
                    hover
                    key={parte._id}
                  >
                    <TableCell>
                      {parte?.Id}
                    </TableCell>
                    <TableCell>
                      {parte.numero_orden}
                    </TableCell>
                    <TableCell>
                      {parte.tag}
                    </TableCell>
                    <TableCell>
                      {parte.fecha_inspeccion ? format(new Date(parte.fecha_inspeccion), 'dd/MM/yyyy') : "-"}
                    </TableCell>

                    <TableCell>
                      <StyledChipUpdate
                        value={parte.informe_realizado}
                        data={parte}
                        edit={parteEdit}
                        field={'informe_realizado'}
                        label={'Informe Realizado'}
                        id={parte._id}
                        handleReload={handleReload}
                        rol={user.role}
                      />
                    </TableCell>
                    <TableCell>
                      <StyledChipUpdate
                        value={parte.informe_revisado}
                        data={parte}
                        edit={parteEdit}
                        field={'informe_revisado'}
                        label={'Informe Revisado'}
                        id={parte._id}
                        handleReload={handleReload}
                        rol={user.role}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {loading &&
                [...Array(rowsPerPage)].map((x, i) =>
                  <TableRow key={i} >
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </Box>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rowsCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </Card>
  )
}