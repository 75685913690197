import { Line } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography, IconButton, Tooltip } from '@mui/material';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardGetKPIObjetivos, dashboardKPIIndividualesGapCargaInspeccion, dashboardSetKPIObjetivos } from '../../services/dashboard';
import { segmentacionSettings } from "../../utils/segmentacion-settings";
import { colors_palette } from "../../utils/colors-palette";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { genericoXLS } from '../../utils/exports/generico-xls'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import KPIObjetivo from './components/kpi-objetivos';
Chart.register(...registerables);

export const KPIIndividualesLineaCargaInspeccion = ({ filters, handleReload, ...props }) => {
  const settings = segmentacionSettings('mensual')
  const [results, setResults] = useState([])

 
  useEffect(() => {
    const getData = async () => {
      const res = await dashboardKPIIndividualesGapCargaInspeccion({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])

  const gaps = results.map((result, index) => {

    return ({
      label: `${result?.operador}`,
      data: result.data,
      borderColor: colors_palette[index],
      yAxisID: 'y',
    })
  })

  //Obtiene las fechas de inicio y fin redondeadas
  const data = {
    datasets: [
      ...gaps,
    ]
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        fontSize: 2,
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
      tooltip: {
        mode: 'index'
      }
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'value',
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: settings.parser,
          unit: settings.unit,
          format: settings.timeFormat,
          tooltipFormat: settings.tooltipFormat,
          displayFormats: settings.displayFormats
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        
      },
    },
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          //justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.3em 1.4em" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '85%' }}>
            <Typography
              sx={{ m: 1 }}
              variant="h6"
              style={{ fontSize: "1em" }}
            >
              Gap inspección-carga
            </Typography>
          </Grid>
          
        </Grid>
      </Box>
      <Divider />

      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >

        <Line
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
