import { useState } from 'react';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton } from '@mui/material';
import { resolvePath } from '../../../utils/path-resolvers';
import { format } from 'date-fns';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/material';
import { parteDelete } from '../../../services/partes';
import { Checkbox } from '@mui/material';
import StyledChipUpdate from '../../../styled-components/styled-chip-update'
import { parteEdit } from '../../../services/partes';
import { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RowDetails from './row-details';
import { styled } from '@mui/material/styles';

//icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOff from "@mui/icons-material/HighlightOff";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 0px 0px 6px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    } 
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));

function EnhancedTableRow({ handleConfirmDialogChange, handleNotifyChange, columns, parte, selected, handleClick, index, handleReload, handleEdit, rol, selectedToEmpty, ...props }) {
    const [open, setOpen] = useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(parte._id);
    const colums_quantity = columns.filter((column) => column.show === true).length + 3

    const handleDelete = async (id) => {
        try {
            await parteDelete(id)
            handleConfirmDialogChange({
                isOpen: false,
                title: "",
                subTitle: ""
            })
            handleNotifyChange({
                isOpen: true,
                message: 'El parte se eliminó correctamente',
                type: 'error'
            })
            handleReload()
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <>
            <StyledTableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={parte._id}
                selected={isItemSelected}
                style={{ paddingTop: "0px" }}
            >
                <StyledTableCell style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "" }}>
                    <Checkbox
                        onClick={(event) => handleClick(event, parte._id, parte)}
                        color="primary"
                        checked={isItemSelected}
                        size="small"
                        style={{
                            transform: "scale(0.8)",
                            margin: 0,
                            padding: 0
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell padding='none' style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "", fontSize: '7pt', }}>
                    <IconButton
                        aria-label="expand row" 
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ width: "25px", height: "25px", fontSize: '15pt' }}
                    >
                        {open ? <KeyboardArrowUpIcon fontSize="inherit" /> : <KeyboardArrowDownIcon fontSize="inherit" />}
                    </IconButton>
                </StyledTableCell>
                {columns.map((column) => {
                    return (
                        <Fragment key={column.id}>
                            {column.show &&
                                <StyledTableCell
                                    key={column.id}
                                    align={column.numeric ? 'right' : 'left'}
                                    padding={column.disablePadding ? 'none' : 'normal'}
                                    style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "", fontSize: '8pt' }}
                                >
                                    <>
                                        {["text", "contratos", "operadores", "clientes", "area", "paga"].includes(column.type) &&
                                            resolvePath(parte, column.id)
                                        }
                                        {column.type === "number" &&
                                            resolvePath(parte, column.id)
                                        }
                                        {column.type === "select" &&
                                            <StyledChipUpdate
                                                value={resolvePath(parte, column.id)}
                                                data={parte}
                                                edit={parteEdit}
                                                field={column.id}
                                                label={column.label}
                                                id={parte._id}
                                                handleReload={handleReload}
                                                rol={rol}
                                                selectedToEmpty={selectedToEmpty}
                                            />
                                        }
                                        {column.type === "date" &&
                                            (resolvePath(parte, column.id) ? format(new Date(resolvePath(parte, column.id)), 'dd/MM/yyyy') : "-")
                                        }
                                    </>
                                </StyledTableCell>
                            }
                        </Fragment>
                    )
                })}
                <StyledTableCell align={'right'} style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "", fontSize: '8pt' }}>
                    <Stack direction="row" spacing={0}>
                        <IconButton
                            sx={{ width: "25px", height: "25px", fontSize: '12pt' }}
                            disabled={rol === "Administrador" ? false : (parte.remito_realizado || rol=== "Asistente" ? true : false)}
                            onClick={() => {
                                handleEdit({ open: true, parte: parte })
                            }}>
                            <Tooltip title="Editar parte">
                                <EditIcon fontSize="inherit" />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            sx={{ width: "25px", height: "25px", fontSize: '12pt' }}
                            disabled={parte.remito_realizado || rol === "Asistente"}
                            onClick={() => {
                                handleConfirmDialogChange({
                                    isOpen: true,
                                    title: "¿Deseas eliminar este parte?",
                                    subTitle: "Luego de eliminarlo, no podrás recuperar la información.",
                                    onConfirm: () => { handleDelete(parte._id) },
                                    icon: <HighlightOff fontSize='inherit' color="error" />
                                })
                            }}>
                            <Tooltip title="Eliminar parte">
                                <DeleteIcon fontSize="inherit" />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                </StyledTableCell>
            </StyledTableRow >
            <RowDetails open={open} parte={parte} colums_quantity={colums_quantity} handleReload={handleReload} rol={rol} />
            {
                parte.items.slice(1).map((item, index) => {
                    return (
                        <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`${index}a`}
                            selected={isItemSelected}
                            style={{ paddingTop: "0px" }}
                        >
                            <StyledTableCell style={{ backgroundColor: "#F5F5F5" }}>
                            </StyledTableCell>
                            <StyledTableCell padding='none' style={{ backgroundColor: "#F5F5F5", fontSize: '7pt', }}>
                            </StyledTableCell>
                            {
                                columns.map((column) => {
                                    return (
                                        <Fragment key={column.id}>
                                            {column.show &&
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.numeric ? 'right' : 'left'}
                                                    padding={column.disablePadding ? 'none' : 'normal'}
                                                    style={{ backgroundColor: "#F5F5F5", fontSize: '8pt' }}
                                                >
                                                    <>
                                                        {["text", "contratos", "operadores", "clientes", "area", "paga"].includes(column.type) && column.id.includes('0') &&
                                                            resolvePath(parte, column.id.replace('0', index + 1))
                                                        }
                                                        {column.type === "number" && column.id.includes('0') &&
                                                            resolvePath(parte, column.id.replace('0', index + 1))
                                                        }

                                                        {column.type === "date" && column.id.includes('0') &&
                                                            (resolvePath(parte, column.id.replace('0', index + 1)) ? format(new Date(resolvePath(parte, column.id.replace('0', index))), 'dd/MM/yyyy') : "-")
                                                        }
                                                    </>
                                                </StyledTableCell>
                                            }
                                        </Fragment>
                                    )
                                })}
                            <StyledTableCell align={'right'} style={{ backgroundColor: "#F5F5F5", fontSize: '8pt' }}>
                            </StyledTableCell>
                        </StyledTableRow >
                    )
                })
            }

        </>
    );
}


export default EnhancedTableRow