import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';


//Icons
import HighlightOff from "@mui/icons-material/HighlightOff";

export default function EnhancedTableToolbar({ handleConfirmDialogChange, handleNotifyChange, numSelected, selected, listado, handleReload, order, orderBy, search, columns, selectedToEmpty,user, ...props }) {
  
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 0, sm: 0 },
        pb: { xs: 0, sm: 0 },
        //bgcolor:"#F3F4F6",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}

    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontSize: "10pt" }}
        >
          {numSelected} seleccionados
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "13pt" }}
        >
          Certificados
        </Typography>
      )}

    </Toolbar >
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};