import {
  Box,
  Button,
  Card,
  CardContent,
  Grid, Typography
} from '@mui/material';
import StyledDatepickerFilterbar from '../../styled-components/styled-datepicker-filterbar';
import LimitTags from '../../styled-components/styled-autocomplete-limittags';
import LimitTagsSimple from '../../styled-components/styled-autocomplete-limittagssimple';
import { contractGetNames, contractGetAreas } from '../../services/contracts';
import { clientGetNames } from '../../services/clients';
import { userGetNames } from '../../services/users';
import { useState } from 'react'
import StyledSegmentationDate from '../../styled-components/styled-segmentation-date';

function FilterBar({ handleReload, handleFiltersChange, filters, ...props }) {

  const [showFilters, setShowFilters] = useState(true)

  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Box>
          <Typography
            sx={{ m: 1 }}
            variant="h5"
          >
            Dashboard
          </Typography>
        </Box>
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => { setShowFilters(!showFilters) }}
          >
            {`${!showFilters ? "Mostrar" : "Ocultar"} filtros`}
          </Button>
        </Box>
      </Box>
      {showFilters &&

        <Box sx={{ mt: 3 }}>

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={6}
                >
                  <StyledDatepickerFilterbar description={"Fecha Inicio"} handleFiltersChange={handleFiltersChange} id="fecha_inicio" defaultValue={filters.fecha_inicio} />
                </Grid>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={6}
                >
                  <StyledDatepickerFilterbar description={"Fecha Fin"} handleFiltersChange={handleFiltersChange} id="fecha_fin" defaultValue={filters.fecha_fin} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <LimitTags description={"Contratos"} handleFiltersChange={handleFiltersChange} id="contratos" getFunction={contractGetNames} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <LimitTags description={"Operadores"} handleFiltersChange={handleFiltersChange} id="operadores" getFunction={userGetNames} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <LimitTags description={"Clientes"} handleFiltersChange={handleFiltersChange} id="clientes" getFunction={clientGetNames} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <LimitTagsSimple description={"Area"} handleFiltersChange={handleFiltersChange} id="area" getFunction={contractGetAreas} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <StyledSegmentationDate handleFiltersChange={handleFiltersChange} filters={filters} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>}
    </Box>
  );

}

export default FilterBar