import { useEffect, useState, useContext } from 'react';
import { DashboardLayout } from '../layout/layout';
import { Box, Container, Grid } from '@mui/material';
import { InformesInspector } from '../components/dashboard/informes-inspector';
import FilterBar from '../components/dashboard/filter-bar';
import { EnsayosUnidadBarras } from '../components/dashboard/ensayos-unidad-barras';
import { SituacionInformesRemitados } from '../components/dashboard/situacion-informes-remitados';
import { ValorPartesCertificados } from '../components/dashboard/valor-partes-certificados';
import { IndicadorSimple } from '../components/dashboard/indicador-simple';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { GapsTable } from '../components/dashboard/gaps-table';
import { dashboardIndicadoresNumericos } from '../services/dashboard';
import TodayIcon from '@mui/icons-material/Today';
import { GapsLinea } from '../components/dashboard/gaps-linea';
import { GapsBarra } from '../components/dashboard/gaps-barra';
import { EnsayosUnidadTorta } from '../components/dashboard/ensayos-unidad-torta';
import { EstadosTorta } from '../components/dashboard/estados-torta';
import { OperadoresTable } from '../components/dashboard/operadores-table';
import UserContext from '../context/userContext';
import { EstadosTortaEconomico } from '../components/dashboard/estados-torta-economico';
import { EnsayosTipoActividadBarrasCantidad } from '../components/dashboard/ensayos-tipo-actividad-barras-cantidad';
import { EnsayosTipoActividadBarrasEconomico } from '../components/dashboard/ensayos-tipo-actividad-barras-economico';
import { EnsayosClienteBarras } from '../components/dashboard/ensayos-cliente-barras';

function Dashboard() {
  const [user, setUser] = useContext(UserContext);
  const [indicadoresNumericos, setIndicadoresNumericos] = useState([])
  const d = new Date(2022, 8, 1)
  const [filters, setFilter] = useState({ contratos: [], operadores: [], fecha_inicio: d, fecha_fin: new Date(), segmentacion: "mensual", area: [], clientes: [] })

  const handleFiltersChange = (value) => {
    setFilter({
      ...filters,
      ...value
    })
  }

  useEffect(() => {
    async function getList() {
      try {
        const res = await dashboardIndicadoresNumericos({ filters: filters })
        setIndicadoresNumericos(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters])

  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <FilterBar handleFiltersChange={handleFiltersChange} filters={filters} />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}
              >
                <IndicadorSimple
                  value={indicadoresNumericos[0]?.inspecciones_realizadas}
                  subvalue={23}
                  title="Inspecciones Realizadas"
                  subtitle=""
                  Icon={() => <StickyNote2Icon />}
                  backgroundColor='secondary.main'
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}
              >
                <IndicadorSimple
                  value={indicadoresNumericos[0]?.informes_pendientes}
                  subvalue={23}
                  title="Informes Pendientes"
                  subtitle=""
                  Icon={() => <StickyNote2Icon />}
                  backgroundColor='secondary.main'
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}
              >
                <IndicadorSimple
                  value={indicadoresNumericos[0]?.gap_inspeccionRealizada_informeRealizado?.toFixed(2)}
                  subvalue={23}
                  title="GAP Inspección Informe"
                  subtitle=""
                  Icon={() => <TodayIcon />}
                  backgroundColor='secondary.main'
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}
              >
                <IndicadorSimple
                  value={indicadoresNumericos[0]?.gap_inspeccionRealizada_certificadoRealizado?.toFixed(2)}
                  subvalue={23}
                  title="GAP Inspección Certificado"
                  subtitle=""
                  Icon={() => <TodayIcon />}
                  backgroundColor='secondary.main'
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xl={8}
                xs={12}
              >
                <EnsayosUnidadBarras filters={filters} />
              </Grid>
              
              <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
              >
                <EnsayosUnidadTorta filters={filters} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <EnsayosClienteBarras filters={filters} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <EnsayosTipoActividadBarrasCantidad filters={filters} />
              </Grid>
              {user.role === "Administrador" &&
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <EnsayosTipoActividadBarrasEconomico filters={filters} />
                </Grid>
              }
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <GapsLinea filters={filters} />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
              >
                <GapsTable filters={filters} />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xl={8}
                xs={12}
              >
                <GapsBarra filters={filters} />
              </Grid>
              {user.role === "Administrador" &&
                <>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                  >
                    <EstadosTorta filters={filters} />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                  >
                    <EstadosTortaEconomico filters={filters} />
                  </Grid>


                  <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <SituacionInformesRemitados filters={filters} />
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}>
                    <ValorPartesCertificados filters={filters} />
                  </Grid>
                </>
              }
              {user.role === "Supervisor" &&
                <>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    xs={12}
                  >
                    <EstadosTorta filters={filters} />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    xl={8}
                    xs={12}
                  >
                    <SituacionInformesRemitados filters={filters} />
                  </Grid>
                </>
              }
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <InformesInspector filters={filters} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <OperadoresTable filters={filters} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default Dashboard;
