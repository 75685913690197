import { useEffect, useState } from 'react';
import { Table, TableBody, TableHead, TextField, Tooltip, IconButton } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import format from 'date-fns/format';
import DeleteIcon from '@mui/icons-material/Delete';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import { parteChangePrices } from '../../../../../../services/partes';

function EnhacedTableRow({ item, handleReload }) {
    const [edited, setEdited] = useState(false)
    const [valorUnitario, setValorUnitario] = useState(item.valor_unitario)
    const [cantidad, setCantidad] = useState(item.cantidad)
    const [valorTotal, setValorTotal] = useState(item.valor_total)

    useEffect(() => {

        //Setea Edited si el estado es diferente al valor que vino en el item
        if (Number(valorUnitario) !== item.valor_unitario || Number(cantidad) !== item.cantidad) {
            setEdited(true)
        } else {
            setEdited(false)
        }
        setValorTotal((valorUnitario * cantidad).toFixed(2))
    }, [valorUnitario, cantidad, item])

    //LLamada para cambiar los precios
    const changePrices = async () => {
        try {
            const res = await parteChangePrices({ id: item.id, subid: item.sub_id, valor_unitario: valorUnitario, valor_total: valorTotal, cantidad: cantidad })
            handleReload()
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <TableRow key={`${item.id} ${item.clase}`} style={{ backgroundColor: item.clase === "Ítem" ? "#f0f0f0" : "#f9f9f9" }}>
            <TableCell style={{ fontSize: '0.8em' }}>{item.codigo}</TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>{item.detalle}</TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>{item.equipo}</TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>{item.fecha_inspeccion ? format(new Date(item.fecha_inspeccion), 'dd/MM/yyyy') : "-"}{ }</TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>{item.clase}</TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>
                <TextField
                    id="standard-basic"
                    variant="standard"
                    size="small"
                    style={{ width: "70px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={cantidad}
                    type="number"
                    onChange={(event) => {

                        setCantidad(event.target.value)
                    }} />
            </TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>
                <TextField
                    id="standard-basic"
                    variant="standard"
                    size="small"
                    style={{ width: "70px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={valorUnitario}
                    type="number"
                    onChange={(event) => {
                        setValorUnitario(event.target.value)
                    }} />
            </TableCell>
            <TableCell style={{ fontSize: '0.8em' }}>{valorTotal}</TableCell>
            <TableCell>
                <IconButton disabled={!edited} sx={{ ml: 1 }} >
                    <Tooltip title="Guardar cambios">
                        <SaveIcon fontSize="small" onClick={()=>changePrices()}/>
                    </Tooltip>
                </IconButton>

            </TableCell>
        </TableRow>
    )
}

export default EnhacedTableRow
