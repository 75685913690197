import instance from "../config/axios"

//Get ALL
export function dashboardInformesInspector({ filters }) {
    //console.log(filters)
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/informes-inspector?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoUnidadBarras({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-unidad-barras?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoClienteBarras({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-cliente-barras?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${"null"}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoUnidadBarrasAgrupado({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-unidad-barras-agrupado?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoUnidadTotales({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-unidad-totales?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardGaps({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/gaps?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardGapsAverage({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/gaps-average?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEstadosCantidades({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/estados-cantidades?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEstadosEconomico({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/estados-economico?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardSituacionInformeRemitados({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/situacion-informes-remitados?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardValorPartesCertificados({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/valor-partes-certificados?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${"mensual"}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardIndicadoresNumericos({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/indicadores-numericos?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardPerformanceOperadores({ filters, order, orderBy }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/performance-operadores?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}&order=${order}&orderBy=${orderBy}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoTipoActividadBarrasCantidades({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-tipo-actividad-barras-cantidades?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}&agrupado=${filters.agrupado}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoTipoActividadBarrasEconomico({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-tipo-actividad-barras-economico?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}&agrupado=${filters.agrupado}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardEnsayoTipoActividadTotales({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/ensayos-tipo-actividad-totales?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=null&fecha_fin=null&segmentacion=${filters.segmentacion}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//----GAPS OBJKETIVO----//
export function dashboardGetGapsObjetivos() {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/gaps-objetivos`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardSetGapsObjetivos(gaps) {
    let token = localStorage.getItem("token")
    return instance.post(`dashboard/gaps-objetivos`, gaps, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//----FACTORES ECONOMICOS----//
export function dashboardGetFactoresEconomicos() {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/factores-economicos`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardSetFactoresEconomicos(gaps) {
    let token = localStorage.getItem("token")
    return instance.post(`dashboard/factores-economicos`, gaps, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//----COSTO MEDIO USD-----//
export function dashboardGetCostoMedioUSD() {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/costo-medio-usd`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardSetCostoMedioUSD(gaps) {
    let token = localStorage.getItem("token")
    return instance.post(`dashboard/costo-medio-usd`, gaps, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//KPI Grupales
export function dashboardKPIEficienciaGrupal({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/kpi-grupal-eficiencia-productividad?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${"mensual"}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//KPI Individuales
export function dashboardKPIIndividualesGapCargaInspeccion({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/kpi-individual-gap-carga-inspeccion?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${"mensual"}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardKPIIndividualesEficiencia({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/kpi-individual-eficiencia?contratos=${filters.contratos.toString() === "" ? "null" : filters.contratos}&operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&fecha_inicio=${filters.fecha_inicio}&fecha_fin=${filters.fecha_fin}&segmentacion=${"mensual"}&area=${filters.area.toString() === "" ? "null" : filters.area}&clientes=${filters.clientes.toString() === "" ? "null" : filters.clientes}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

//----KPI Grupales OBJETIVO----//
export function dashboardGetKPIObjetivos() {
    let token = localStorage.getItem("token")
    return instance.get(`dashboard/kpi-grupales-objetivos`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function dashboardSetKPIObjetivos(kpi) {
    let token = localStorage.getItem("token")
    return instance.post(`dashboard/kpi-grupales-objetivos`, kpi, {
        headers: { Authorization: `Bearer ${token}` }
    })
}