import { Bar } from 'react-chartjs-2';
import { Box, Card, Divider, Grid,  Typography  , IconButton} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardInformesInspector } from '../../services/dashboard';
Chart.register(...registerables);

export const InformesInspector = ({ filters, ...props }) => {
  const [results, setResults] = useState([])


  useEffect(() => {
    const getData = async () => {
      const res = await dashboardInformesInspector({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])
  const data = {
    datasets: [
      {
        backgroundColor: '#3F51B5',
        barPercentage: 0.5,
        barThickness: 15,
        borderRadius: 0,
        categoryPercentage: 0.5,
        data: results.map((dato) => dato.informes_no_realizados),
        label: 'Informes no realizados',
        maxBarThickness: 15
      },
    ],
    labels: results.map((dato) => `${dato.operador[0]} ${dato.operador[1]}`)
  };
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <Card {...props}>
      <Box style={{ padding: "1em 1em 0.3em 1.4em" }}>
        <Typography 
        variant="h6" 
        gutterBottom
        style={{fontSize: "1em"}}
        >
        Informes pendientes por inspector
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 400,
          position: 'relative',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >
        <Bar
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
