import { CardContent, Toolbar, Box, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Tooltip } from "@mui/material";
import ColumnsEdit from './columns-edit'
import { parteDeleteMany } from "../../../services/partes";
import { parteEstado } from "../../../services/partes";
import PartesListAdd from "../partes-list-add";
import RemitoCreate from "../partes-list-remito";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { userEdit } from '../../../services/users';
import StyledChipUpdateMany from "../../../styled-components/styled-chip-updatemany";

//Icons
import HighlightOff from "@mui/icons-material/HighlightOff";
import { useState } from "react";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExportTable from "./components/export-table";
import FilterBar from "./components/filter-bar";
import ChipFilter from "./components/chip-filter";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function EnhancedTableToolbar({ handleStartLoading,
  search, handleSearchChange, handleConfirmDialogChange, handleNotifyChange, numSelected,
  selected, remito, handleReload, selectedToEmpty, user, handleUserChange, fullScrean,
  order, orderBy, columns, ...props }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  const saveFilters = async ({ field, value }) => {
    //Setea la prebusqueda, que queda guardada en el usuario(local)
    handleUserChange({
      ...user, search:
      {
        ...user.search,
        [field]: value,
      }
    })
    //handleSearchChange({ ...search, [field]: value })
    try {
      await userEdit({
        ...user, search:
        {
          ...user.search,
          [field]: value,
        }
      }, user._id)
    } catch (e) {
      console.log(e)
    }
    handleReload()
  }

  const handleDelete = () => {
    parteDeleteMany(selected)
    handleConfirmDialogChange({
      isOpen: false,
      title: "",
      subTitle: ""
    })
    handleNotifyChange({
      isOpen: true,
      message: 'Los partes se eliminaron correctamente correctamente',
      type: 'error'
    })
    selectedToEmpty()
    handleReload()
  }

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          pt: { xs: 0, sm: 0 },
          pb: { xs: 0, sm: 0 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
        variant="dense"

      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            style={{ fontSize: "10pt" }}
          >
            {numSelected} seleccionados
          </Typography>
        ) : (
          <>
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
              style={{ fontSize: "13pt" }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
                style={{ width: '100%' }}>
                <ListItem>
                  Parte Diario
                </ListItem>
                <ChipFilter
                  saveFilters={saveFilters}
                  setExpanded={handleExpanded}
                  handleFiltersChange={handleSearchChange}
                  expanded={expanded}
                  search={search}
                  id="contratos"
                  label="Contratos"
                  show={!(search.contratos ? search.contratos.toString() === "" : true)}
                />
                <ChipFilter
                  saveFilters={saveFilters}
                  setExpanded={handleExpanded}
                  handleFiltersChange={handleSearchChange}
                  expanded={expanded}
                  search={search}
                  id="operadores"
                  label="Operadores"
                  show={!(search.operadores ? search.operadores.toString() === "" : true)}
                />
                <ChipFilter
                  saveFilters={saveFilters}
                  setExpanded={handleExpanded}
                  handleFiltersChange={handleSearchChange}
                  expanded={expanded}
                  search={search}
                  id="area"
                  label="Áreas"
                  show={!(search.area ? search.area.toString() === "" : true)}
                />
                <ChipFilter
                  saveFilters={saveFilters}
                  setExpanded={handleExpanded}
                  handleFiltersChange={handleSearchChange}
                  expanded={expanded}
                  search={search}
                  id="clientes"
                  label="Clientes"
                  show={!(search.clientes ? search.clientes.toString() === "" : true)}
                />

              </Box>
            </Typography>
            <Tooltip title="Filtros generales">
              <IconButton size="small" onClick={() => setExpanded(!expanded)}>
                <FilterAltIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
        {numSelected > 0 ? (
          <>

            <StyledChipUpdateMany
              data={remito}
              selected={selected}
              handleConfirmDialogChange={handleConfirmDialogChange}
              handleNotifyChange={handleNotifyChange}
              handleReload={handleReload}
              selectedToEmpty={selectedToEmpty}
              rol={user.role}
              edit={parteEstado}
            />
            <RemitoCreate
              remito={remito}
              selected={selected}
              handleConfirmDialogChange={handleConfirmDialogChange}
              handleNotifyChange={handleNotifyChange}
              handleReload={handleReload}
              selectedToEmpty={selectedToEmpty}
            />
            <Tooltip title="Borrar ítems">
              <IconButton size="small" onClick={() => {
                handleConfirmDialogChange({
                  isOpen: true,
                  title: "¿Deseas eliminar los partes seleccionados?",
                  subTitle: "Luego de eliminarlos, no podrás recuperar la información.",
                  onConfirm: () => { handleDelete() },
                  icon: <HighlightOff fontSize='inherit' color="error" />
                })
              }} >
                <Delete fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            {!fullScrean &&
              <Tooltip title="Pantalla completa">
                <IconButton size="small" component={Link} to="/partes-full">
                  <OpenInFullIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            }
            {fullScrean &&
              <Tooltip title="Pantalla normal">
                <IconButton size="small" component={Link} to="/partes-list">
                  <CloseFullscreenIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            }
            {(user.role === "Administrador" || user.role === "Supervisor") &&
              <ExportTable
                handleConfirmDialogChange={handleConfirmDialogChange}
                handleNotifyChange={handleNotifyChange}
                handleReload={handleReload}
                order={order}
                orderBy={orderBy}
                search={search}
                columns={columns}
                user={user}
              />}
            <ColumnsEdit
              handleReload={handleReload}
            />
            <PartesListAdd
              handleReload={handleReload}
              handleConfirmDialogChange={handleConfirmDialogChange}
              handleNotifyChange={handleNotifyChange}
            />
          </>
        )
        }
      </Toolbar >
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent style={{ padding: '0em 1em 1em 1em' }}>
          <Box style={{ padding: '1em 0em 0em 0em' }}>
            <FilterBar
              handleSearchChange={handleSearchChange}
              search={search}
              handleUserChange={handleUserChange}
              user={user}
              handleStartLoading={handleStartLoading}
              handleReload={handleReload}
              saveFilters={saveFilters}
            />
          </Box>
        </CardContent>
      </Collapse>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};