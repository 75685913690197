import { format } from 'date-fns';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Paper,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Skeleton
} from '@mui/material';
import { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { remitoGetAll, remitoEstado } from '../../../../services/remitos';
import StyledChipUpdate from '../../../../styled-components/styled-chip-update';



export const RemitosTable = ({ handleReload, reload, user, ...props }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const remitos = data?.docs || []

  //Pagination states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const rowsCount = data.totalDocs || 6
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true)
    handleReload()
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleReload()
    setLoading(true)
  };

  useEffect(() => {

    async function getList() {
      try {
        const res = await remitoGetAll(page, rowsPerPage, 'asc', "_id", { certificado_realizado: false, remito_firmado: false })
        setData(res.data)
        setLoading(false)

      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [reload])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = remitos.length < rowsPerPage ? rowsPerPage - remitos.length : 0;

  return (
    <Card style={{ height: "570px" }} {...props}>
      <CardHeader title="Remitos con estados pendientes" />
      <Paper sx={{ overflowX: "auto", width: "100%", height: '430px' }}>
        <Box>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  Número Remito
                </TableCell>
                <TableCell sortDirection="desc">
                  Fecha
                </TableCell>
                <TableCell>
                  Revisado
                </TableCell>
                <TableCell>
                  Entregado
                </TableCell>
                <TableCell>
                  Firmado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                remitos.map((remito) => (
                  <TableRow
                    hover
                    key={remito._id}
                  >
                    <TableCell>
                      {remito?._id}
                    </TableCell>
                    <TableCell>
                      {remito.fecha ? format(new Date(remito.fecha), 'dd/MM/yyyy') : "-"}
                    </TableCell>

                    <TableCell >
                      <StyledChipUpdate
                        value={remito.remito_revisado}
                        data={remito}
                        edit={remitoEstado}
                        field={'remito_revisado'}
                        label={'Remito Revisado'}
                        id={remito._id}
                        handleReload={handleReload}
                        rol={user.role}
                      />
                    </TableCell>
                    <TableCell>
                      <StyledChipUpdate
                        value={remito.remito_entregado}
                        data={remito}
                        edit={remitoEstado}
                        field={'remito_entregado'}
                        label={'Remito Entregado'}
                        id={remito._id}
                        handleReload={handleReload}
                        rol={user.role}
                      />
                    </TableCell>
                    <TableCell>
                      <StyledChipUpdate
                        value={remito.remito_firmado}
                        data={remito}
                        edit={remitoEstado}
                        field={'remito_firmado'}
                        label={'Remito Firmado'}
                        id={remito._id}
                        handleReload={handleReload}
                        rol={user.role}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {loading &&
                [...Array(rowsPerPage)].map((x, i) =>
                  <TableRow key={i} >
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </Box>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rowsCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </Card>
  )
}