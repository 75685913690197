import { Box, Container } from '@mui/material';
import { useState,useEffect } from 'react';
import { UsersListResults } from '../components/users-list/users-list-results';
import UsersListToolbar from '../components/users-list/users-list-toolbar';
import { DashboardLayout } from '../layout/layout';
import {  userSearch } from '../services/users';

function UsersList() { 
  const [reload, setReload] = useState(false)
  const [search, setSearch] = useState("") 
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    async function getList() {
      try {
        const users = await userSearch(search)
        setUsers(users.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    getList()
  }, [reload,search])

  const handleReload = () => {
    setReload(!reload)
  }
  const handleSearchChange = (value) => {
    setSearch(value)
  }

  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}
        >
          <Container maxWidth={false}>
            <UsersListToolbar handleReload={handleReload} handleSearchChange={handleSearchChange}/>
            <Box sx={{ mt: 3 }}>
              <UsersListResults handleReload={handleReload} users={users} loading={loading}/>
            </Box>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default UsersList;
