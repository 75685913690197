import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Divider
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const GapsStep = ({ primary, secondary, value, divider, ...props }) => {

    return (
        <>
            <ListItem
                style={{ height: "35px" }}
            >

                <ListItemAvatar>
                    <Typography variant="h5" style={{ padding: "0px 0px 0px 0px" }}>
                        {value}
                    </Typography>
                    <Typography variant="h6"
                        color="text.primary"
                        style={{ fontSize: "0.7em", padding: "0px 0px 0px 7px" }}>
                        días
                    </Typography>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography
                            variant="h6"
                            color="text.primary"
                            style={{ fontSize: "0.9em" }}
                        >
                            {primary}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            variant="body2"
                            color="text.primary"
                            style={{ fontSize: "0.65em" }}
                        >
                            {secondary}
                        </Typography>
                    }
                />

            </ListItem>
            {divider &&
                <Divider ><Typography
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "1em", padding: "0px 0px 0px 0px", margin: "0px 0px 0px 0px" }}
                >
                    <ArrowDownwardIcon fontSize='inerith' />
                </Typography></Divider>
            }
        </>
    )
}