import { Box, Container, Grid, Typography } from '@mui/material';
import { DashboardLayout } from '../layout/layout';
import UpdateUsers from '../components/settings/update-users';
import FactoresEconomicos from '../components/settings/factores-economicos';
import CostoMedioUSD from '../components/settings/costo-medio-usd';


function Settings() {

    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
            >
                <Container>
                    <Typography
                        sx={{ mb: 3 }}
                        variant="h4"
                    >
                        Configuración
                    </Typography>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}>
                            <FactoresEconomicos />
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}>
                            <CostoMedioUSD />
                        </Grid>
                        
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}>
                            <UpdateUsers />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </DashboardLayout>
    )
}


export default Settings;
