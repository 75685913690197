import instance from "../config/axios"

//Get ALL
export function inicioIndicadoresAdministrador() {
    let token = localStorage.getItem("token")
    return instance.get(`inicio/administrador/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function inicioIndicadoresSupervisor({ filters }) {
    let token = localStorage.getItem("token")
    return instance.get(`inicio/supervisor?operadores=${filters.operadores.toString() === "" ? "null" : filters.operadores}&area=${filters.area.toString() === "" ? "null" : filters.area}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function inicioIndicadoresInspector({ id }) {
    let token = localStorage.getItem("token")
    return instance.get(`inicio/inspector/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function inicioGraficoInspector({ id, month, year }) {
    let token = localStorage.getItem("token")
    return instance.get(`inicio/inspector/grafico/${id}-${month}-${year}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function inicioIndicadoresAsistente() {
    let token = localStorage.getItem("token")
    return instance.get(`inicio/asistente/`, {
        headers: { Authorization: `Bearer ${token}` }
    })
}
