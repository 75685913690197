import { useState, useEffect, useMemo } from 'react';
import { Box, Card, Table, TableBody, Paper } from '@mui/material';
import { parteGetRestricted } from '../../services/partes';
import EnhancedTableToolbar from './table/enhanced-table-toolbar';
import EnhancedTableHead from './table/enhanced-table-head';
import EnhancedTableSearch from './table/enhanced-table-search';
import EnhancedTableRow from './table/enhanced-table-row';
import TablePagination from '@mui/material/TablePagination';
import UserContext from '../../context/userContext';
import PartesListEdit from './partes-list-edit';
import { useContext } from 'react';
import EnhancedTableSkeleton from './table/enhanced-table-skeleton';

//Debounce para la busqueda
import { BehaviorSubject, from } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, map } from "rxjs/operators";

//Alerts y Notifications
import Notification from '../../styled-components/alerts/notification';
import ConfirmDialog from '../../styled-components/alerts/confirm-dialog';
import { v4 as uuidv4 } from 'uuid';


export const PartesListResults = ({ heigth, fullScrean, ...props }) => {
  const [user, setUser] = useContext(UserContext);
  const [edit, setEdit] = useState({ open: false, parte: [] })
  const [reload, setReload] = useState(false)
  const [data, setData] = useState([])
  const partes = data?.docs || []
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
  const [remito, setRemito] = useState([])

  //Array de filas para mapear skeleton
  let rows = [], i = 0, len = 25;
  while (++i <= len) rows.push(i);

  //Cambios en usuario
  const handleUserChange = (user) => {
    setUser(user)
  }

  //Loading
  const [loading, setLoading] = useState(true)
  const handleStartLoading = () => {
    setLoading(true)

  }

  const handleEdit = (value) => {
    setEdit(value)
  }

  const handleConfirmDialogChange = (value) => {
    setConfirmDialog({
      ...confirmDialog,
      ...value
    })
  }

  const handleNotifyChange = (value) => {
    setNotify(value)
  }

  const handleReload = () => {
    setReload(!reload)
  }

  //Selected 
  const [selected, setSelected] = useState([]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partes.map((n) => n._id);
      const newItemsRemito = partes
      setSelected(newSelecteds);
      setRemito(newItemsRemito);
      return;
    }
    setSelected([]);
    setRemito([]);
  };

  const handleClick = (event, name, item) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    let newItem = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      newItem = newItem.concat(remito, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newItem = newItem.concat(remito.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newItem = newItem.concat(remito.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newItem = newItem.concat(
        remito.slice(0, selectedIndex),
        remito.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected);
    setRemito(newItem)
  };

  //Selected to emty
  const selectedToEmpty = () => {
    setSelected([])
    setRemito([])
  }

  //Search filers
  let user_filters = user?.search
  user.parteColumns.filter((filter) => filter.search !== null).map((filter) =>
    user_filters = { ...user_filters, [filter.id]: filter.search }
  )

  const [search, setSearch] = useState(user_filters)
  const handleSearchChange = (newValue) => {
    setSearch(newValue)
    setLoading(true);
  }

  //Sort states and functions
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Id');
  const handleRequestSort = (event, property) => {
    setLoading(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Pagination states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsCount = data.totalDocs || 25
  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);

  };
  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  //Debounce para la busqueda
  const searchParams$ = useMemo(() => new BehaviorSubject([]), []);

  useEffect(() => {
    searchParams$.next({ page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, search: search });
  }, [searchParams$, reload, page, rowsPerPage, order, orderBy, search]);

  useEffect(() => {
    async function getList(requestId) {
      try {
        const res = await parteGetRestricted(
          searchParams$.value.page,
          searchParams$.value.rowsPerPage,
          searchParams$.value.order,
          searchParams$.value.orderBy,
          searchParams$.value.search,
          requestId
        );
        setData(res.data);
        setLoading(false);
        return { requestId, data: res.data };
      } catch (error) {
        console.log(error.response.data.error);
        console.log(error);
      }
    }
    const res = searchParams$
    .pipe(
      debounceTime(500),
      switchMap(() => {
        const requestId = uuidv4();
        return from(getList(requestId));
      }),
      map(res => ({ requestId: res.data?.requestId, data: res.data }))
    )
    .subscribe();
    return () => res.unsubscribe();
  }, []);
  console.log(data)



  return (
    <>
      <Card sx={{}}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          remito={remito}
          handleReload={handleReload}
          handleConfirmDialogChange={handleConfirmDialogChange}
          handleNotifyChange={handleNotifyChange}
          selectedToEmpty={selectedToEmpty}
          search={search}
          handleSearchChange={handleSearchChange}
          user={user}
          handleUserChange={handleUserChange}
          fullScrean={fullScrean}
          order={order}
          orderBy={orderBy}
          columns={user.parteColumns}
        />
        <Paper sx={{ overflowX: "auto", width: "100%", height: `${heigth}` }}>
          {/* <Box sx={{ minWidth: 300, maxWidth: 2300 }}> */}
          <Box >
            <Table stickyHeader size="small" >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                numSelected={selected.length}
                rowCount={partes.length}
                onSelectAllClick={handleSelectAllClick}
                columns={user.parteColumns}
                dense={true}
              />
              <TableBody>
                <EnhancedTableSearch
                  columns={user.parteColumns}
                  search={search}
                  handleSearchChange={handleSearchChange}
                  handleStartLoading={handleStartLoading}
                />

                {loading &&
                  rows.map((item, index) =>
                    <EnhancedTableSkeleton key={index} columns={user.parteColumns} />)
                }

                {!loading && partes?.map((parte, index) => {


                  return (
                    <EnhancedTableRow
                      key={parte._id}
                      parte={parte}
                      handleReload={handleReload}
                      handleClick={handleClick}
                      index={index}
                      selected={selected}
                      columns={user.parteColumns}
                      handleConfirmDialogChange={handleConfirmDialogChange}
                      handleNotifyChange={handleNotifyChange}
                      handleEdit={handleEdit}
                      rol={user.role}
                      selectedToEmpty={selectedToEmpty}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          variant="dense"
          count={rowsCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            height: "52px",
            fontSize: "9pt",
            backgroundColor: "#F3F4F6",
            ".MuiToolbar-root": {
              backgroundColor: "#F3F4F6",
              heigh: "10px",
            },
            "& .MuiTablePagination-selectLabel": {
              fontSize: "8pt",
            },
            "& 	.MuiTablePagination-root": {
              fontSize: "8pt",
            },
            "& 	.MuiTablePagination-actions": {
              fontSize: "8pt",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "8pt",
            },
            '.MuiTablePagination-actions': {
              '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px'
              },
              '& .MuiButtonBase-root': {
                height: '20px',
                width: '20px'
              },
            },
            '.MuiTablePagination-toolbar': {
              color: 'rgb(41, 39, 39)',
              //height: '30px',
            },
          }}
          SelectProps={{
            MenuProps: {
              sx: {
                '.MuiTablePagination-menuItem': {
                  fontSize: "8pt",
                },
              },
            },
          }}
          labelRowsPerPage={"Filas por página"}
        />

      </Card>
      <Notification
        notify={notify}
        setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog} />
      {
        edit.open &&
        <PartesListEdit edit={edit} handleEdit={handleEdit} handleNotifyChange={handleNotifyChange} handleReload={handleReload} />
      }
    </>
  );
};