import { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { IconButton } from '@mui/material';
import { resolvePath } from '../../../../utils/path-resolvers';
import { format } from 'date-fns';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/material';
import { certificadoDelete, certificadoEstado } from '../../../../services/certificados';
import { Checkbox } from '@mui/material';
import StyledChipUpdate from '../../../../styled-components/styled-chip-update';
import { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { certificadoXLS } from '../../../../utils/exports/certificado-xls';
import { certificadoXLSAdaptado } from '../../../../utils/exports/certificado-xls-adaptado';
import RowDetails from '../table/row-details';
import { styled } from '@mui/material/styles';


//icons
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOff from "@mui/icons-material/HighlightOff";
import DownloadIcon from '@mui/icons-material/Download';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import StyledDatepickerUpdate from '../../../../styled-components/styled-datepicker-update';
import StyledTextfieldUpdate from '../../../../styled-components/styled-textfield-update';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 0px 0px 6px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    } 
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));


function EnhancedTableRow({ handleConfirmDialogChange, handleNotifyChange, columns, certificado, selected, handleClick, index, handleReload, handleEdit, rol, ...props }) {
    const [open, setOpen] = useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(certificado._id);
    const labelId = `enhanced-table-checkbox-${index}`;
    

    //Cantidad de columnas mostradas
    const colums_quantity = columns.filter((column) => column.show === true).length + 3

    const handleDelete = async (id) => {
        try {
            await certificadoDelete(id)
            handleConfirmDialogChange({
                isOpen: false,
                title: "",
                subTitle: ""
            })
            handleNotifyChange({
                isOpen: true,
                message: 'El certificado se eliminó correctamente',
                type: 'error'
            })
            handleReload()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>

            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={certificado._id}
                selected={isItemSelected}
                style={{ paddingTop: "0px" }}
            >
                <StyledTableCell padding='none' style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "" }}>
                    <Checkbox
                        onClick={(event) => handleClick(event, certificado._id, certificado)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        style={{
                            transform: "scale(0.8)",
                            margin: 0,
                            padding: 0
                        }}
                    />
                </StyledTableCell>
                <StyledTableCell padding='none' style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "" }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                         sx={{ width: "25px", height: "25px", fontSize: '15pt' }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                {columns.map((column) => {
                    return (
                        <Fragment key={column.id}>
                            {column.show &&
                                <StyledTableCell
                                    key={column.id}
                                    align={column.numeric ? 'right' : 'left'}
                                    padding={column.disablePadding ? 'none' : 'normal'}
                                    style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "", fontSize: '8pt' }}
                                >
                                    <>
                                        {(column.type === "text" && column.id !== "hes") &&
                                            resolvePath(certificado, column.id)
                                        }
                                        {column.type === "number" &&
                                            `${new Intl.NumberFormat('de-DE').format(resolvePath(certificado, column.id)?.toFixed(2) || 0)}`

                                        }
                                        {column.type === "select" &&
                                            <StyledChipUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol} />
                                        }
                                        {column.type === "date" &&
                                            (resolvePath(certificado, column.id) ? format(new Date(resolvePath(certificado, column.id)), 'dd/MM/yyyy') : "-")
                                        }
                                        {column.type === "text_editable" &&
                                            <StyledTextfieldUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol} />
                                        }
                                        {column.type === "date_editable" &&
                                            <StyledDatepickerUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol}
                                            />
                                        }
                                    </>
                                </StyledTableCell>
                            }
                        </Fragment>
                    )
                })}
                <StyledTableCell style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "" }}>
                    <Stack direction="row" spacing={0.5}>
                        <Tooltip title="Descargar Certificado">
                            <IconButton sx={{ width: "25px", height: "25px", fontSize: '12pt' }} onClick={() => {
                                certificadoXLS(certificado)
                            }}>
                                <DownloadIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Descargar Certificado Adaptado para DOW">
                            <IconButton sx={{ width: "25px", height: "25px", fontSize: '12pt' }} onClick={() => {
                                certificadoXLSAdaptado(certificado)
                            }}>
                                <DownloadForOfflineIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar certificado">
                            <IconButton sx={{ width: "25px", height: "25px", fontSize: '12pt' }} onClick={() => {
                                handleConfirmDialogChange({
                                    isOpen: true,
                                    title: "¿Deseas eliminar este certificado?",
                                    subTitle: "Luego de eliminarlo, no podrás recuperar la información.",
                                    onConfirm: () => { handleDelete(certificado._id) },
                                    icon: <HighlightOff fontSize='inherit' color="error" />
                                })
                            }}>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </StyledTableCell>
            </TableRow >
            <RowDetails
                open={open}
                certificado={certificado}
                colums_quantity={colums_quantity}
                handleReload={handleReload}
                handleConfirmDialogChange={handleConfirmDialogChange}
                handleNotifyChange={handleNotifyChange}
                rol={rol} />
        </>
    );
}


export default EnhancedTableRow