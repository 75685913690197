import {
  Box,
  Button,
  Card,
  TextField,
  InputAdornment,
  SvgIcon, Typography
} from '@mui/material';
import { Search as SearchIcon } from '../../icons/search';
import { useNavigate } from "react-router-dom";

function UsersListToolbar({ handleReload, handleSearchChange, ...props }) {
  const navigate = useNavigate();

  function handleNewUser() {
    navigate("/users-create")
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1
        }}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
          Usuarios
        </Typography>
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => { handleNewUser() }}
          >
            Nuevo Usuario
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Card>
          <Box sx={{ maxWidth: 500, padding: "1.5em 1.5em 1.5em 1.5em" }}>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      color="action"
                      fontSize="small"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Buscar usuario"
              variant="outlined"
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );

}

export default UsersListToolbar