import React, { useState, useEffect } from "react";
import { Box, Table, TableHead, TableBody, TableRow, Paper, Button, Card, CardHeader } from '@mui/material';
import TableCell from "@mui/material/TableCell";
import { dashboardGetCostoMedioUSD, dashboardSetCostoMedioUSD } from "../../services/dashboard";
import Notification from '../../styled-components/alerts/notification';
import ConfirmDialog from '../../styled-components/alerts/confirm-dialog';

//Icons
import EditIcon from '@mui/icons-material/Edit';
import CostoMedioUSDRow from "./components/costo-medio-usd-row";

function CostoMedioUSD({ handleOpenChange, handleReload, open, ...props }) {
    const [costos, setCostos] = useState([])
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })

    //Obtiene la data del gráfico y la afecta por los costos económicos
    useEffect(() => {
        const getData = async () => {
            const res = await dashboardGetCostoMedioUSD();
            console.log(res.data)
            setCostos(res.data)
        }
        getData()
    }, [])

    const handleCostosChange = (value) => {
        setCostos(value)
    }

    const editCostos = async () => {
        if (costos.toString() !== "") {
            try {
                await dashboardSetCostoMedioUSD(costos)
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                  })
                  setNotify({
                    isOpen: true,
                    message: `Los costos se modificaron correctamente`,
                    type: 'success'
                  })
            } catch (e) {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                  })
                  setNotify({
                    isOpen: true,
                    message: 'Ha habido un error, intente nuevamente',
                    type: 'error'
                  })
                  console.log(e)
            }
        }

        handleReload()
    }

    async function onSubmit(contract) {
        setConfirmDialog({
            isOpen: true,
            title: `¿Desea guardar los cambios realizados en el el costo medio?`,
            subTitle: "",
            onConfirm: () => { editCostos(contract) },
            icon: <EditIcon fontSize='inherit' color="success" />
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={'Costo medio en USD'}
                    subheader={'Modifique y guarde el costo medio para cada mes. Este valor será utilizado en los Dashboards para obtener algunos KPI'}
                />

                <Paper sx={{ overflowX: "auto", width: "100%", height: `230px` }}>
                    <Box style={{ padding: "0em 0em 0em 0em" }}>

                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {`Fecha`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {`Precio Dolar [$]`}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    costos.map((factor, index) => {
                                        return (
                                            <CostoMedioUSDRow handleCostosChange={handleCostosChange} costos={costos} value={factor.value} date={factor.date} key={index} index={index} />
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
                <Button onClick={() => { onSubmit() }}>Guardar y actualizar</Button>

            </Card>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
CostoMedioUSD.defaultProps = {
    handleOpenChange: () => { },
    handleReload: () => { },
    open: true
}

export default CostoMedioUSD;