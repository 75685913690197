import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

//Alerts y Notifications
import Notification from './alerts/notification';
import NotificationDialog from './alerts/notification-dialog';
import ConfirmDialog from './alerts/confirm-dialog';

import { allowedRoles } from "../utils/list";

//icons 
import EventIcon from '@mui/icons-material/Event';
import BlockIcon from '@mui/icons-material/Block';


export default function StyledChipUpdate({ handleReload, edit, value, field, id, label, onChangeFunction, rol, data, selectedToEmpty, ...props }) {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [notificationDialog, setNotificationDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [disable, setDisable] = useState(true)
    const handleDisabled = (value) => {
        setDisable(value)
    }

    useEffect(() => {
        //Dependiento el rol del usuario, se habilita o no el 
        const field_allowed = allowedRoles.filter((item) => item.rol === rol)[0]?.fields_allowed
        if (field_allowed?.includes(field)) {

            field === 'trabajo_terminado' && data.informe_realizado === true && handleDisabled(true);
            field === 'trabajo_terminado' && data.informe_realizado === false && handleDisabled(false);

            field === 'informe_realizado' && (data.trabajo_terminado === false || data.informe_revisado === true) && handleDisabled(true);
            field === 'informe_realizado' && data.informe_revisado === false && data.trabajo_terminado === true && handleDisabled(false);

            field === 'informe_revisado' && (data.informe_realizado === false || data.remito_realizado === true) && handleDisabled(true);
            field === 'informe_revisado' && data.remito_realizado === false && data.informe_realizado === true && handleDisabled(false);

            field === 'remito_realizado' && handleDisabled(true);

            field === 'remito_revisado' && (!!!data.remito_realizado_fecha === false || data.remito_entregado === true) && handleDisabled(true);
            field === 'remito_revisado' && data.remito_entregado === false && !!!data.remito_realizado_fecha === true && handleDisabled(false);

            field === 'remito_entregado' && (data.remito_revisado === false || data.remito_firmado === true) && handleDisabled(true);
            field === 'remito_entregado' && data.remito_firmado === false && data.remito_revisado === true && handleDisabled(false);

            field === 'remito_firmado' && (data.remito_entregado === false || data.certificado_realizado === true) && handleDisabled(true);
            field === 'remito_firmado' && data.certificado_realizado === false && data.remito_entregado === true && handleDisabled(false);

            field === 'certificado_realizado' && handleDisabled(true);

            field === 'certificado_finalizado' && (data.certificado_realizado === false || data.certificado_facturado === true) && handleDisabled(true);
            field === 'certificado_finalizado' && data.certificado_facturado === false && data.certificado_realizado === true && handleDisabled(false);

            field === 'certificado_facturado' && data.certificado_finalizado === false  && handleDisabled(true);
            field === 'certificado_facturado' && data.certificado_finalizado === true && handleDisabled(false);

        } else {
            handleDisabled(true)
        }
    }, [
        data.trabajo_terminado,
        data.informe_realizado,
        data.informe_revisado,
        data.remito_realizado,
        data.remito_entregado,
        data.remito_firmado,
        data.remito_realizado_fecha,
        data.remito_revisado,
        data.certificado_realizado,
        data.certificado_finalizado,
        data.certificado_facturado,
        field,
        rol
    ])

    function handleRequestToChange() {
        if (disable) {
            setNotificationDialog({
                isOpen: true,
                title: `No es posible cambiar el estado`,
                onConfirm: () => {
                    setNotificationDialog({
                        ...notificationDialog,
                        isOpen: false
                    })
                },
                icon: <BlockIcon fontSize='inherit' color="error" />
            })
        } else {
            setConfirmDialog({
                isOpen: true,
                title: `¿Desea cambiar el estado de "${label}" a ${value ? "NO" : "SI"}?`,
                subTitle: value ? `Al confirmar, se eliminará la fecha registrada y no podrá volver atrás` :
                    `Al confirmar, se guardará la fecha del dia de hoy`,
                onConfirm: () => { handleEdit() },
                icon: <EventIcon fontSize='inherit' color={value ? "success" : "error"} />
            })
        }
    }


    async function handleEdit() {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        try {
            await edit({ data: { [field]: !value }, id })
            setNotify({
                isOpen: true,
                message: `El estado se modificó correctamente a ${value ? "NO" : "SI"}`,
                type: value ? "error" : "success"
            })
            handleReload()
            selectedToEmpty()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Tooltip title={!disable ? (value ? "Cambiar a NO" : "Cambiar a SI") : "NO permitido"}>
                <Chip size="small" sx={{ width: "40px" }} label={value ? "SI" : "NO"} color={value ? "success" : "error"}
                    onClick={() => {
                        handleRequestToChange()
                    }}
                    variant={disable ? 'outlined' : "filled"}
                    clickable={!disable} />
            </Tooltip>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog} />
            <NotificationDialog
                confirmDialog={notificationDialog}
                setConfirmDialog={setNotificationDialog} />
        </>
    );
}

StyledChipUpdate.defaultProps = {
    selectedToEmpty: () => { }
}