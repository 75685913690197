import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

//Alerts y Notifications
import Notification from './alerts/notification';
import NotificationDialog from './alerts/notification-dialog';
import ConfirmDialog from './alerts/confirm-dialog';

import SaveIcon from '@mui/icons-material/Save';
import EventIcon from '@mui/icons-material/Event';


export default function StyledTextfieldUpdate({ handleReload, edit, value, field, id, label, onChangeFunction, rol, data, selectedToEmpty, ...props }) {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [notificationDialog, setNotificationDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [newValue, setNewValue] = useState(value)
    const [edited, setEdited] = useState(false)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        //Setea Edited si el estado es diferente al valor que vino en el item
        if (newValue !== value) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [value, newValue])

    useEffect(() => {
        !!!value ? setNewValue(null) : setNewValue(value)
        //Se agregan condiciones particulares para desabilitar el TextBox
        if (field === "factura_numero") {
            !!data.certificado_facturado_fecha ? setDisabled(false) : setDisabled(true)
        } else if (field === "hes") {
            !!data.certificado_finalizado_fecha ? setDisabled(false) : setDisabled(true)
        }
    }, [value, data.certificado_facturado_fecha, data.certificado_finalizado_fecha, field])

    async function handleEdit(value) {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        try {
            //await edit({ data: { [field]: value1 }, id });
            await edit({ data: { [field]: value }, id });
            //setNewValue(value)
            setNotify({
                isOpen: true,
                message: `El estado se modificó la fecha correctamente`,
                type: "success"
            });
            handleReload();
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Box display={"flex"}>
                <TextField
                    disabled={disabled}
                    value={newValue || ""}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setNewValue(e.target.value)}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: 25,
                            fontSize: 10,
                        }
                    }}
                    placeholder='-'
                />
                <IconButton disabled={!edited} sx={{ width: "25px", height: "25px", fontSize: '12pt' }}>
                    <Tooltip title="Guardar cambios" >
                        <SaveIcon fontSize="inherit" onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: `¿Desea cambiar la fecha`,
                                subTitle: '',
                                onConfirm: () => { handleEdit(newValue) },
                                icon: <EventIcon fontSize='inherit' color={newValue ? "success" : "error"} />
                            })
                        }} />
                    </Tooltip>
                </IconButton>
            </Box>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog} />
            <NotificationDialog
                confirmDialog={notificationDialog}
                setConfirmDialog={setNotificationDialog} />
        </>
    );
}

StyledTextfieldUpdate.defaultProps = {
    selectedToEmpty: () => { }
}