import { Doughnut } from 'react-chartjs-2';
import { Box, Grid, Card, Divider, Typography, IconButton, Collapse, TableBody, TableRow, TableHead, Table } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { useState, useEffect } from 'react';
import { dashboardEstadosCantidades } from '../../services/dashboard';
import { colors_palette } from "../../utils/colors-palette";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
Chart.register(...registerables);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: "3px 15px 3px 15px",
    borderBottom: "0.1px solid #F5F5F5",
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const EstadosTorta = ({ filters, ...props }) => {
  const [results, setResults] = useState([])
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await dashboardEstadosCantidades({ filters: filters })
      setResults(res.data)
    }
    getData()
  }, [filters])

  const data = {
    datasets: [
      {
        data: [
          results[0]?.inspeccion_realizada,
          results[0]?.trabajo_terminado,
          results[0]?.informe_realizado,
          results[0]?.informe_revisado,
          results[0]?.remito_realizado,
          results[0]?.remito_revisado,
          results[0]?.remito_entregado,
          results[0]?.remito_firmado,
          results[0] ? undefined : 50

        ],
        backgroundColor: [
          colors_palette[0],
          colors_palette[1],
          colors_palette[2],
          colors_palette[3],
          colors_palette[4],
          colors_palette[5],
          colors_palette[6],
          colors_palette[7],
          results[0] ? undefined : '#f1f1f1'
        ],
        //borderColor: results.map((dato, index) => colors_palette[index]),
        //fill: true
      }
    ],
    labels: [
      "Trabajo no terminado",
      "Informe no realizado",
      "Informe no revisado",
      "Remito no realizado",
      "Remito no revisado",
      "Remito no entregado",
      "Remito no firmado",
      "Sin certificar"
    ]

  }
  const total = data.datasets[0].data.reduce((accumulator, currentValue) => accumulator + (currentValue === undefined ? 0 : currentValue), 0)

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          footer: (ttItem) => {
            let sum = 0;
            let dataArr = ttItem[0].dataset.data;
            dataArr.map(data => {
              sum += Number(data ? data : 0);
            });
            let percentage = (ttItem[0].parsed * 100 / sum).toFixed(2) + '%';
            return `${percentage}`;
          }
        }
      },
      legend: {
        position: 'top',
        labels: {
          padding: 5,
          boxWidth: 12,
          boxHeight: 6,
        }
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1
        }}
        style={{ padding: "1em 1em 0.7em 1.4em" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '75%' }}>
            <Typography
              sx={{ m: 1 }}
              variant="h6"
              style={{ fontSize: "1em" }}
            >
              Estado de inspecciones cantidades
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Table size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Estado
            </StyledTableCell>
            <StyledTableCell align='right'>
              Cantidad
            </StyledTableCell>
            <StyledTableCell align='right'>
              Porcentaje
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.labels.map((key, index) => {
            return (
              <TableRow key={index}>
                <StyledTableCell >
                  {key}
                </StyledTableCell >
                <StyledTableCell align='right'>
                  {`${new Intl.NumberFormat('de-DE').format(data.datasets[0].data[index]?.toFixed(0) || 0)}`}
                </StyledTableCell >
                <StyledTableCell align='right'>
                  {`${Math.round(data.datasets[0].data[index] * 100 / total) || 0}%`}
                </StyledTableCell >
              </TableRow>)
          })}
        </TableBody>
      </Table>
      <Box
        sx={{
          height: 350,
          position: 'relative',
          padding: '0.5em 1em 1em 1em'
        }}
      >
        <Doughnut
          data={data}
          options={options}
        />
      </Box>
    </Card>
  );
};
