import React from "react";
import { Collapse, TextField, Box, Grid } from '@mui/material';

function KPIObjetivo({ KPI, handleKPIChange, expanded, open, ...props }) {
    const handleChange = ({ key, value }) => {
        handleKPIChange({ ...KPI, [key]: value })
    }
    

    return (

        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ padding: "1em 1em 1em 1.5em" }}>
                <Grid container spacing={2}>
                    <Grid item >
                        <TextField
                            required
                            label="Eficiencia"
                            value={KPI.eficiencia || ""}
                            size="small"
                            type="number"
                            onChange={(event) => handleChange({ key: "eficiencia", value: event.target.value })}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            label="Productividad"
                            value={KPI.productividad || ""}
                            size="small"
                            type="number"
                            onChange={(event) => handleChange({ key: "productividad", value: event.target.value })}
                        />
                    </Grid>
                </Grid>

            </Box>
        </Collapse>
    );
}

export default KPIObjetivo;