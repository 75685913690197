import { Grid } from '@mui/material';
import StyledDatepickerFilterbar from '../../../../styled-components/styled-datepicker-filterbar';
import LimitTags from '../../../../styled-components/styled-autocomplete-limittags';
import LimitTagsSimple from '../../../../styled-components/styled-autocomplete-limittagssimple';
import { contractGetNames, contractGetAreas } from '../../../../services/contracts';
import { clientGetNames } from '../../../../services/clients';
import { userGetNames } from '../../../../services/users';

function FilterBar({ handleReload, handleSearchChange, user, search,handleUserChange, saveFilters ,...props }) {

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        xs={12}
      >
        <LimitTags description={"Contratos"} handleFiltersChange={handleSearchChange} search={search} id="contratos" getFunction={contractGetNames} defaultValue={user.search?.contratos} saveFilters={saveFilters}/>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        xs={12}
      >
        <LimitTags description={"Operadores"} handleFiltersChange={handleSearchChange} search={search} id="operadores" getFunction={userGetNames} defaultValue={user.search?.operadores} saveFilters={saveFilters}/>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        xs={12}
      >
        <LimitTagsSimple description={"Area"} handleFiltersChange={handleSearchChange} search={search} id="area" getFunction={contractGetAreas} defaultValue={user.search?.area} saveFilters={saveFilters}/>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        xs={12}
      >
        <LimitTags description={"Clientes"} handleFiltersChange={handleSearchChange} search={search} id="clientes" getFunction={clientGetNames} defaultValue={user.search?.clientes} saveFilters={saveFilters}/>
      </Grid>
       <Grid
        item
        xl={2}
        lg={2}
        md={2}
        xs={12}
      >
        <StyledDatepickerFilterbar description={"Fecha Inicio "} handleFiltersChange={handleSearchChange} search={search} id="fecha_inicio" defaultValue={search["fecha_inicio"] ? search["fecha_inicio"] : null } saveFilters={saveFilters}/>
      </Grid>
      <Grid
        item
        xl={2}
        lg={2}
        md={2}
        xs={12}
      >
        <StyledDatepickerFilterbar description={"Fecha Fin"} handleFiltersChange={handleSearchChange} search={search} id="fecha_fin" defaultValue={search["fecha_fin"] ? search["fecha_fin"] : null } saveFilters={saveFilters}/>
      </Grid> 
    </Grid>
  );
}

export default FilterBar