import React, { useState } from "react";
import { Collapse, TextField, Box, Grid, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 17px 0px 17px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    }
}));

function FactoresEconomicosRow({ factores, dolar_price, date, handleFactoresChange, expanded, open, index, ...props }) {
    const [factor, setFactor] = useState(dolar_price)

    const handleChange = ({ index, value }) => {
        let newFactores = factores
        newFactores[index].dolar_price = Number(value)
        handleFactoresChange(newFactores)
        setFactor(value)
    }

    return (

        <TableRow >
            <StyledTableCell>
                {date}
            </StyledTableCell>
            <StyledTableCell align="right">
                <TextField
                    required
                    variant="standard"
                    style={{ width: "70px" }}
                    inputProps={{ style: { fontSize: "0.7em" } }}
                    value={factor || 1}
                    size="small"
                    type="number"
                    onChange={(event) => handleChange({ index, value: event.target.value })}
                />
            </StyledTableCell>
        </TableRow>

    );
}

export default FactoresEconomicosRow;