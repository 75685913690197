import React, { useState, useEffect } from "react";
import { Collapse, TextField, Box, Grid, Table, TableHead, TableBody, TableRow, Paper, Button, Card, CardHeader } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';
import FactoresEconomicosRow from "./components/factores-economicos-row";
import { dashboardGetFactoresEconomicos, dashboardSetFactoresEconomicos } from "../../services/dashboard";

import Notification from '../../styled-components/alerts/notification';
import ConfirmDialog from '../../styled-components/alerts/confirm-dialog';

//Icons
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: "0px 17px 0px 17px",
        borderBottom: "0.1px solid #F5F5F5",
        //bordetTop:4
    }
}));

function FactoresEconomicos({ handleOpenChange, handleReload, open, ...props }) {
    const [factores, setFactores] = useState([])
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })

    //Obtiene la data del gráfico y la afecta por los factores económicos
    useEffect(() => {
        const getData = async () => {
            const res = await dashboardGetFactoresEconomicos();
            setFactores(res.data)
        }
        getData()
    }, [])

    const handleFactoresChange = (value) => {
        setFactores(value)
    }

    const editFactores = async () => {
        if (factores.toString() !== "") {
            try {
                await dashboardSetFactoresEconomicos(factores)
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                  })
                  setNotify({
                    isOpen: true,
                    message: `Los factores se modificaron correctamente`,
                    type: 'success'
                  })
            } catch (e) {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                  })
                  setNotify({
                    isOpen: true,
                    message: 'Ha habido un error, intente nuevamente',
                    type: 'error'
                  })
                  console.log(e)
            }
        }

        handleReload()
    }

    async function onSubmit(contract) {
        setConfirmDialog({
            isOpen: true,
            title: `¿Desea guardar los cambios realizados en el tipo de cambio?`,
            subTitle: "",
            onConfirm: () => { editFactores(contract) },
            icon: <EditIcon fontSize='inherit' color="success" />
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={'Factores económicos'}
                    subheader={'Modifique y guarde el precio del dolar para cada mes. Este precio será utilizado en los Dashboards para convertir de Pesos Argentinos a Dolares'}
                />

                <Paper sx={{ overflowX: "auto", width: "100%", height: `230px` }}>
                    <Box style={{ padding: "0em 0em 0em 0em" }}>

                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {`Fecha`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {`Precio Dolar [$]`}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    factores.map((factor, index) => {
                                        return (
                                            <FactoresEconomicosRow handleFactoresChange={handleFactoresChange} factores={factores} dolar_price={factor.dolar_price} date={factor.date} key={index} index={index} />
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
                <Button onClick={() => { onSubmit() }}>Guardar y actualizar</Button>

            </Card>
            <Notification
                notify={notify}
                setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
FactoresEconomicos.defaultProps = {
    handleOpenChange: () => { },
    handleReload: () => { },
    open: true
}

export default FactoresEconomicos;