export const tipos_actividad = [
    "ADICIONAL", "CAL", "CRO", "CUAD", "DUR", "ESP", "EXT", "GE", "GR", "HH", "INT", "IV", "JOR", "ME", "MT", "OG", "PA",
    "PEC", "PLA", "PLACA", "PT", "RC", "RD", "REP", "RX", "SCAN-B", "STAND BY", "TER", "UT", "VIA", "VIS",
]
export const subtipos_actividad = [
    "Ítem",
    "Subítem"
]
export const area = [
    "Inspección",
    "Radiografía"
]
export const unidades_medida = [
    "Día",
    "Hora Hombre",
    "Hora Normalizada",
    "Jornada",
    "Mes",
    "Metro lineal",
    "Metro lineal de soldadura (MLS)",
    "Porcentaje adicional",
    "Punto de medición de espesores (TML)",
    "Unidad",
    "Unidad (dia x persona)",
    "Unidad (croquis/hoja)",
]
export const role = [
    "Administrador", "Supervisor", "Inspector", "Asistente", "Cliente"
]

export const tipo_rx = [
    "Normal", "Exposiciones", "Reparaciones", "Horas", "Metros"
]

export const contratista = [
    "BSI", "DOW", "HCI", "FB", "UTE"
]

export const paga = [
    "BSI", "DOW", "HCI", "FB", "UTE"
]

//Roles permitidos
export const allowedRoles = [
    { rol: "Administrador", fields_allowed: ["trabajo_terminado", "informe_realizado", "informe_revisado", "remito_realizado", "certificado_realizado", "remito_revisado", "remito_entregado", "remito_firmado", "certificado_realizado", "certificado_finalizado", "certificado_facturado"] },
    { rol: "Supervisor", fields_allowed: ["trabajo_terminado", "informe_realizado", "informe_revisado", "remito_realizado", "remito_revisado", "certificado_realizado"] },
    { rol: "Inspector", fields_allowed: ["trabajo_terminado", "informe_realizado"] },
    { rol: "Asistente", fields_allowed: ["trabajo_terminado", "informe_realizado", "informe_revisado", "remito_realizado", "certificado_realizado", "remito_revisado", "remito_entregado", "remito_firmado", "certificado_realizado", "certificado_finalizado", "certificado_facturado"] },
]
//Correlatividades
export const dependencies = [
    { pre: "trabajo_terminado", post: "ninguno" },
    { pre: "informe_realizado", post: "trabajo_terminado" },
    { pre: "informe_revisado", post: "informe_realizado" },
    { pre: "remito_realizado", post: "informe_revisado" },
    { pre: "remito_revisado", post: "remito_realizado" },
    { pre: "remito_entregado", post: "remito_revisado" },
    { pre: "remito_firmado", post: "remito_entregado" },
    { pre: "certificado_realizado", post: "remito_firmado" },
    { pre: "certificado_finalizado", post: "certificado_realizado" },
    { pre: "certificado_facturado", post: "certificado_finalizado" },
    { pre: "ninguno", post: "certificado_facturado" },
]