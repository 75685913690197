import { Box, Container } from '@mui/material';
import { CertificadosListResults } from '../components/certificados-list/certificados-list-results/certificados-list-results'
import { DashboardLayout } from '../layout/layout';
import { useContext } from 'react';
import UserContext from '../context/userContext';
import { CertificadosListAsistente } from '../components/certificados-list/certificados-list-asistente/certificados-list-asistente';

function CertificadosList() {
  const [user, setUser] = useContext(UserContext);
  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {user.role === "Administrador" && <CertificadosListResults />}
            {user.role === "Asistente" && <CertificadosListAsistente />}
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}


export default CertificadosList;
