import { TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

//Alerts y Notifications
import Notification from './alerts/notification';
import NotificationDialog from './alerts/notification-dialog';
import ConfirmDialog from './alerts/confirm-dialog';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//icons 
import EventIcon from '@mui/icons-material/Event';
import { DesktopDatePicker } from '@mui/x-date-pickers';


export default function StyledDatepickerUpdate({ handleReload, edit, value, field, id, label, onChangeFunction, rol, data, selectedToEmpty, ...props }) {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "success" })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [notificationDialog, setNotificationDialog] = useState({ isOpen: false, title: "", subTitle: "" })
    const [date, setDate] = useState(value)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        !!!value ? setDate(null) : setDate(value)
        !!!value ? setDisabled(true) : setDisabled(false)
    }, [value])

    async function handleEdit(value) {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        try {
            await edit({ data: { [field]: value }, id })
            setDate(value)
            setNotify({
                isOpen: true,
                message: `El estado se modificó la fecha correctamente`,
                type: "success"
            })
            handleReload()
            selectedToEmpty()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    disabled={disabled}
                    {...props}
                    inputFormat="dd/MM/yyyy"
                    value={date || null}
                    onChange={(value) => {
                        setConfirmDialog({
                            isOpen: true,
                            title: `¿Desea cambiar la fecha`,
                            subTitle: '',
                            onConfirm: () => { handleEdit(value) },
                            icon: <EventIcon fontSize='inherit' color={value ? "success" : "error"} />
                        })
                    }}
                    renderInput={(params) =>
                        <TextField
                            size="small"
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 25,
                                    fontSize: 10,
                                }
                            }}{...params} />}
                />
                <Notification
                    notify={notify}
                    setNotify={setNotify} />
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog} />
                <NotificationDialog
                    confirmDialog={notificationDialog}
                    setConfirmDialog={setNotificationDialog} />
            </LocalizationProvider>
        </>
    );
}

StyledDatepickerUpdate.defaultProps = {
    selectedToEmpty: () => { }
}