import { Doughnut } from 'react-chartjs-2';
import { Select, Box, Card, CardContent, CardHeader, Divider, Typography, useTheme, Button, Chip, TextField } from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIcon from '@mui/icons-material/Phone';
import TabletIcon from '@mui/icons-material/Tablet';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import { inicioGraficoInspector } from '../../../../services/inicio';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MonthToWord } from '../../../../utils/month-to-word';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export const PartesTorta = ({ handleReload, reload, user, ...props }) => {
  let hoy = new Date();
  const theme = useTheme();
  const [month, setMonth] = useState(("0" + (hoy.getMonth() + 1)).slice(-2))
  const [year, setYear] = useState(hoy.getFullYear())
  const [datos, setDatos] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMonthChange = (event) => {
    setMonth(event.target.value)
  }
  const handleYearChange = (event) => {
    setYear(event.target.value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await inicioGraficoInspector({ id: user._id, month: month, year: year })
      setDatos(res.data)
    }
    getData()
  }, [reload, month, year])

  const data = {
    datasets: [
      {
        data: [
          datos[0]?.trabajo_no_terminado,
          datos[0]?.informe_no_realizado,
          datos[0]?.informe_en_revision,
          datos[0] ? undefined : 50
        ],
        backgroundColor: ['#e53935', '#FB8C00', '#3DB981', datos[0] ? undefined : '#f1f1f1'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['Trabajos no terminados', 'Informes no realizados', 'Informes en revisión']
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Trabajos no terminados',
      value: datos[0]?.trabajo_no_terminado ? Math.round(datos[0]?.trabajo_no_terminado * 100 / (datos[0]?.trabajo_no_terminado + datos[0]?.informe_no_realizado + datos[0]?.informe_en_revision)) : "-",
      icon: LaptopMacIcon,
      color: '#E53935'
    },
    {
      title: 'Informes no realizados',
      value: datos[0]?.informe_no_realizado ? Math.round(datos[0]?.informe_no_realizado * 100 / (datos[0]?.trabajo_no_terminado + datos[0]?.informe_no_realizado + datos[0]?.informe_en_revision)) : "-",
      icon: TabletIcon,
      color: '#FB8C00'
    },
    {
      title: 'Informes en revisión',
      value: datos[0]?.informe_en_revision ? Math.round(datos[0]?.informe_en_revision * 100 / (datos[0]?.trabajo_no_terminado + datos[0]?.informe_no_realizado + datos[0]?.informe_en_revision)) : "-",
      icon: PhoneIcon,
      color: '#3DB981'
    }
  ];

  return (
    <Card {...props}>
      <CardHeader
        title="Estado Mensual"
        action={
          <Chip label={`${MonthToWord(month)} ${year}`} color="primary" variant="outlined" onClick={handleClick} />
        }
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box style={{width:"150px"}}>
          <MenuItem>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              onChange={handleMonthChange}
              size="small"
              style={{ maxHeight: "50px",width:"100%" }}
              MenuProps={MenuProps}
            >
              <MenuItem value={"01"} name={"Enero"}>Enero</MenuItem>
              <MenuItem value={"02"} name={"Febrero"}>Febero</MenuItem>
              <MenuItem value={"03"}>Marzo</MenuItem>
              <MenuItem value={"04"}>Abril</MenuItem>
              <MenuItem value={"05"}>Mayo</MenuItem>
              <MenuItem value={"06"}>Junio</MenuItem>
              <MenuItem value={"07"}>Julio</MenuItem>
              <MenuItem value={"08"}>Agosto</MenuItem>
              <MenuItem value={"09"}>Septiembre</MenuItem>
              <MenuItem value={"10"}>Octubre</MenuItem>
              <MenuItem value={"11"}>Noviembre</MenuItem>
              <MenuItem value={"12"}>Diciembre</MenuItem>
            </Select>
          </MenuItem>
          <MenuItem>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year}
              onChange={handleYearChange}
              size="small"
              style={{ maxHeight: "50px",width:"100%" }}
              MenuProps={MenuProps}
            >
              <MenuItem value={"2024"}>2024</MenuItem>
              <MenuItem value={"2023"}>2023</MenuItem>
              <MenuItem value={"2022"}>2022</MenuItem>
              <MenuItem value={"2021"}>2021</MenuItem>
              <MenuItem value={"2020"}>2020</MenuItem>
              <MenuItem value={"2019"}>2019</MenuItem>
            </Select>
          </MenuItem>
        </Box>
      </Menu>
      <Divider />

      <CardContent >

        <Box
          sx={{
            height: 300,
            position: 'relative',
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              {/* <Icon color="action" /> */}
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h4"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
