import { Collapse} from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Stack } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { certificadoEstado } from '../../../../services/certificados';
import StyledChipUpdate from '../../../../styled-components/styled-chip-update';
import format from 'date-fns/format';
import RowDetailsTable from './row-details-table/row-details-table';

function RowDetails({ open, certificado, colums_quantity, handleReload, handleConfirmDialogChange, handleNotifyChange, rol, ...props }) {
    

    return (
        <TableRow style={{ backgroundColor: "#F3F4F6" }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colums_quantity}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Grid container spacing={4} style={{ padding: "1em" }}>
                    <Grid item>
                            <Box style={{ width: "900px", paddingBottom: "1em" }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Ítems y Adicionales
                                </Typography>
                               <RowDetailsTable  handleReload={handleReload} certificado={certificado}/>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box style={{ maxWidth: "300px" }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Datos generales
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                    Operador/a
                                </Typography>
                                <Typography variant="body2" gutterBottom component="div">
                                    {certificado?.operador[0].nombre} {certificado?.operador[0].apellido}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                    Planta
                                </Typography>
                                <Typography variant="body2" gutterBottom component="div">
                                    {certificado?.planta}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                    Contrato
                                </Typography>
                                <Typography variant="body2" gutterBottom component="div">
                                    {certificado?.contrato[0].nombre}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                    Certificado
                                </Typography>
                                <Typography variant="body2" gutterBottom component="div">
                                    {certificado?.certificado_numero}
                                </Typography>
                            </Box>
                            <Box style={{ maxWidth: "300px" }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Estado
                                </Typography>
                                <Stack direction="column" spacing={0} style={{ paddingBottom: "1em" }}>
                                    <Stack direction="row" spacing={1}>
                                        <StyledChipUpdate
                                            value={certificado.certificado_finalizado}
                                            edit={certificadoEstado}
                                            field={"certificado_finalizado"}
                                            label={"Remito Finalizado"}
                                            id={certificado._id}
                                            handleReload={handleReload} 
                                            data={certificado}
                                            rol={rol}/>
                                        <Box>
                                            <Typography variant="subtitle2" gutterBottom component="div">
                                                Certificado Finalizado
                                            </Typography>
                                            <Typography variant="caption" gutterBottom component="div">
                                                Fecha: {certificado.certificado_finalizado ? format(new Date(certificado.certificado_finalizado_fecha), 'dd/MM/yyyy') : "-"}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export default RowDetails

