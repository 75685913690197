
import { Tooltip } from "@mui/material";
import { useEffect, useState } from 'react';
import { Box} from '@mui/material';
import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

//Icons
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import NuevoCertificado from "./certificado/nuevo-certificado";
import SumarCertificado from "./certificado/sumar-certificado";


export default function CertificadoCreate({ handleReload, handleEdit, certificado, selected, handleConfirmDialogChange, handleNotifyChange,selectedToEmpty, ...props }) {
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //Formulario para crear nuevo remito
  const [openNuevo, setOpenNuevo] = useState(false)
  const handleOpenNuevo = (value) => {
    setOpenNuevo(value)
  }

  //Formulario para Sumar items a un remito
  const [openSumar, setOpenSumar] = useState(false)
  const handleOpenSumar = (value) => {
    setOpenSumar(value)
  }

  useEffect(() => {
    let estado = !certificado.some((item) => (item.remito_realizado === true || item.remito_entregado === false || item.remito_firmado === false || item.remito_revisado === false|| item.certificado_realizado === true))
    setShow(estado)
  }, [certificado])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {show &&
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Tooltip title="Nuevo Certificado">
            <IconButton
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleClick}>
              <ListAltIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
     <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          }, 
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {
          handleClose()
          handleOpenNuevo(true)
        }}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText ><Typography style={{ fontSize: "0.9em" }}>Crear nuevo certificado</Typography></ListItemText>
        </MenuItem>

        <MenuItem onClick={() => {
          handleClose()
          handleOpenSumar(true)
        }}>
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Typography style={{ fontSize: "0.9em" }}>Añadir remito a certificado</Typography></ListItemText>
        </MenuItem>
      </Menu>
      <NuevoCertificado
        openNuevo={openNuevo}
        handleOpenNuevo={handleOpenNuevo}
        certificado={certificado}
        handleConfirmDialogChange={handleConfirmDialogChange}
        handleNotifyChange={handleNotifyChange}
        selected={selected}
        selectedToEmpty={selectedToEmpty}
        handleReload={handleReload}
      />
      <SumarCertificado
        openSumar={openSumar}
        handleOpenSumar={handleOpenSumar}
        certificado={certificado}
        handleConfirmDialogChange={handleConfirmDialogChange}
        handleNotifyChange={handleNotifyChange}
        selected={selected}
        selectedToEmpty={selectedToEmpty}
        handleReload={handleReload}
      />
    </div>
  );
}