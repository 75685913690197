import { Chip } from "@mui/material";
import { styled } from '@mui/material/styles';

//Icons
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function ChipFilter({ saveFilters, setExpanded, expanded, show, id, label, handleFiltersChange, search, ...props }) {

    return (
        <>
            {show &&
                <ListItem>
                    <Chip
                        size="small"
                        color="primary"
                        label={label}
                        onDelete={() => {
                            saveFilters({ field: id, value: [] })
                            handleFiltersChange({ ...search, [id]: [] })
                        }}
                        deleteIcon={<FilterAltOffIcon />}
                        onClick={() => {
                            setExpanded()
                        }}
                    />
                </ListItem>
            }
        </>
    );
};
