import { Table, TableBody, TableHead, TextField, Tooltip, IconButton } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import format from 'date-fns/format';
import DeleteIcon from '@mui/icons-material/Delete';
import EnhacedTableRow from './components/enhanced-table-row';

function RowDetailsTable({ certificado,handleReload }) {

    return (
        <Table size="small">
            <TableHead>
                <TableRow style={{ backgroundColor: "#d8d8d8", height: "6em", fontSize: '0.5em' }}>
                    <TableCell >CÓDIGO</TableCell>
                    <TableCell >DESCRIPCIÓN</TableCell>
                    <TableCell >EQUIPO</TableCell>
                    <TableCell >FECHA</TableCell>
                    <TableCell>CLASE</TableCell>
                    <TableCell>CANTIDAD</TableCell>
                    <TableCell>VALOR UNITARIO</TableCell>
                    <TableCell>VALOR TOTAL</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {certificado.items.map((item, index) => {
                    return (
                        <EnhacedTableRow handleReload={handleReload}  key={`${item.id} ${item.clase} ${index}`} item={item} />
                    )
                })}
            </TableBody>
        </Table>

    )
}

export default RowDetailsTable
