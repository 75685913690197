import {
  Box,
  Card,
  Divider,
  List,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardGapsAverage } from '../../services/dashboard';
import { GapsStep } from './components/gaps-step';


export const GapsTable = ({ filters, ...props }) => {
  const [data, setData] = useState([])


  useEffect(() => {
    async function getList() {
      try {
        const res = await dashboardGapsAverage({ filters: filters })
        setData(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    getList()
  }, [filters])

  return (
    < Card style={{ height: "540px" }} {...props}>
      <Box style={{ padding: "1em 1em 0.3em 1.4em" }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontSize: "1em" }}>
          Días promedio
          {/* <CircularProgress size={15}/> */}
        </Typography>
      </Box>
      <Divider />
      
          <List style={{ paddingTop: 15, margin: 0 }}>
            <>
              <GapsStep
                value={data[0]?.inspeccionRealizada_trabajoTerminado !== null ? data[0]?.inspeccionRealizada_trabajoTerminado.toFixed(1) : 'S/D'}
                primary={"Trabajo terminado"}
                secondary={"Inspeccion realizada - Trabajo terminado"}
                divider={true}
              />
              <GapsStep
                value={data[0]?.trabajoTerminado_informeRealizado !== null ? data[0]?.trabajoTerminado_informeRealizado.toFixed(1) : 'S/D'}
                primary={"Informe realizado"}
                secondary={"Trabajo terminado - Informe realizado"}
                divider={true}
              />

              <GapsStep
                value={data[0]?.informeRevisado_remitoRealizado !== null ? data[0]?.informeRealizado_informeRevisado.toFixed(1) : 'S/D'}
                primary={"Informe revisado"}
                secondary={"Informe realizado - Informe revisado"}
                divider={true}
              />
              <GapsStep
                value={data[0]?.informeRevisado_remitoRealizado !== null ? data[0]?.informeRevisado_remitoRealizado.toFixed(1) : 'S/D'}
                primary={"Remito emitido"}
                secondary={"Informe revisado - Remito realizado"}
                divider={true}
              />
              <GapsStep
                value={data[0]?.remitoRealizado_remitoRevisado !== null ? data[0]?.remitoRealizado_remitoRevisado.toFixed(1) : 'S/D'}
                primary={"Remito revisado"}
                secondary={"Remito realizado - Remito revisado"}
                divider={true}
              />
              <GapsStep
                value={data[0]?.remitoRevisado_remitoEntregado !== null ? data[0]?.remitoRevisado_remitoEntregado.toFixed(1) : 'S/D'}
                primary={"Remito entregado"}
                secondary={"Remito revisado - Remito entregado"}
                divider={true}
              />

              <GapsStep
                value={data[0]?.remitoEntregado_remitoFirmado !== null ? data[0]?.remitoEntregado_remitoFirmado.toFixed(1) : 'S/D'}
                primary={"Firma cliente"}
                secondary={"Remito entregado - Remito firmado"}
                divider={true}
              />
              <GapsStep
                value={data[0]?.remitoFirmado_certificadoRealizado !== null ? data[0]?.remitoFirmado_certificadoRealizado.toFixed(1) : 'S/D'}
                primary={"Certificación"}
                secondary={"Remito firmado - Certificado realizado"}
                divider={false}
              />
            </>
          </List>
    </Card >
  )
}