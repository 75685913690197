import { Box, Container, Grid } from '@mui/material';
import { IndicadorSimple } from './components/indicador-simple';
import { InicioTable } from './components/table';
import { useEffect, useState } from 'react';
import { inicioIndicadoresSupervisor } from '../../services/inicio';
import UserContext from './../../context/userContext';
import { useContext } from 'react';
import { Filtros } from './components/filtros';
import { PartesTable } from './components/inicio-supervisor/partes-table';
import { RemitosTable } from './components/inicio-supervisor/remitos-table';

//Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ReceiptIcon from '@mui/icons-material/Receipt';


function InicioSupervisor() {
  const [filters, setFilters] = useState({ operadores: [], area: [] })
  const [reload, setReload] = useState(false)
  const [user, setUser] = useContext(UserContext);
  const [indicadores, setIndicadores] = useState([])

  const handleFiltersChange = (value) => {
    setFilters({
      ...filters,
      ...value
    })
  }

  const handleReload = () => {
    setReload(!reload)

  }
  useEffect(() => {
    const getIndicadores = async () => {
      try {
        const res = await inicioIndicadoresSupervisor({ filters: filters })
        setIndicadores(res.data)
      } catch (e) {
        console.log(e)
      }
    }
    getIndicadores()
  }, [reload, filters])

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={4}
              lg={6}
              md={6}
              sm={12}
              xs={12}

            >
              <Filtros
                handleFiltersChange={handleFiltersChange}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={3}
              md={3}
              sm={6}
              xs={12}
            >
              <IndicadorSimple
                value={indicadores[0]?.partes_sin_informe}
                subvalue={indicadores[0]?.partes_sin_informe}
                title="Inspecciones sin Informe"
                subtitle=""
                Icon={() => <StickyNote2Icon />}
                backgroundColor='secondary.main'
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={3}
              md={3}
              sm={6}
              xs={12}
            >
              <IndicadorSimple
                value={indicadores[0]?.informes_para_revision}
                subvalue={indicadores[0]?.informes_para_revision}
                title="Informes para Revision"
                subtitle=""
                Icon={() => <StickyNote2Icon />}
                backgroundColor='secondary.main'
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              <RemitosTable/>
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              xl={8}
              xs={12}
            >
              <PartesTable user={user} handleReload={handleReload} reload={reload} filters={filters}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default InicioSupervisor;