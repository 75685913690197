import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {

  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 944.434 208.8"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath28" clipPathUnits="userSpaceOnUse">
          <path
            d="M662.725 613.981l-23.248-23.302-13.955-13.955c-26.247-26.247-26.247-69.096 0-95.344 26.235-26.234 69.097-26.247 95.345 0l18.641 18.642c25.613 25.612 7.659 70.069-29.03 70.069-10.509 0-21.046-3.998-29.044-11.996l-18.642-18.641c-5.741-5.741-5.741-15.063 0-20.804 5.742-5.741 15.063-5.741 20.805 0l13.196 13.198-7.212 7.214 37.081 6.173-6.187-37.067-7.213 7.213-13.199-13.198c-23.559-23.559-64.841-7.295-64.841 26.883 0 9.753 3.688 19.493 11.077 26.882l18.277 18.277-.054.054 18.952 18.952.027-.027c5.741 5.741 5.741 15.063 0 20.804a14.602 14.602 0 01-10.361 4.292 14.69 14.69 0 01-10.415-4.319"
            className="svg-elem-1"
          ></path>
        </clipPath>
        <linearGradient
          id="linearGradient38"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(145.69144 0 0 -145.69144 605.837 540)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#04d8dc" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#01f4a0" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath48" clipPathUnits="userSpaceOnUse">
          <path d="M0 1080h1920V0H0z" className="svg-elem-2"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -807.782 824.4)">
        <g>
          <g clipPath="url(#clipPath28)">
            <g>
              <g>
                <path
                  fill='url("#linearGradient38")'
                  stroke="none"
                  d="M662.725 613.981l-23.248-23.302-13.955-13.955c-26.247-26.247-26.247-69.096 0-95.344 26.235-26.234 69.097-26.247 95.345 0l18.641 18.642c25.613 25.612 7.659 70.069-29.03 70.069-10.509 0-21.046-3.998-29.044-11.996l-18.642-18.641c-5.741-5.741-5.741-15.063 0-20.804 5.742-5.741 15.063-5.741 20.805 0l13.196 13.198-7.212 7.214 37.081 6.173-6.187-37.067-7.213 7.213-13.199-13.198c-23.559-23.559-64.841-7.295-64.841 26.883 0 9.753 3.688 19.493 11.077 26.882l18.277 18.277-.054.054 18.952 18.952.027-.027c5.741 5.741 5.741 15.063 0 20.804a14.602 14.602 0 01-10.361 4.292 14.69 14.69 0 01-10.415-4.319"
                  className="svg-elem-3"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath48)">
            <g transform="translate(785.182 546.493)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 27.788 20.259 49.553 46.13 49.553 21.081 0 37.097-12.046 43.12-31.621H69.812C65.295 26.83 57.081 32.99 46.13 32.99 30.389 32.99 18.068 17.932 18.068 0c0-18.069 12.321-33.126 28.062-33.126 13.552 0 23.408 9.719 26.009 21.217H48.595V4.928h43.392c1.095-33.4-18.754-54.481-45.857-54.481C20.259-49.553 0-27.788 0 0"
                className="svg-elem-4"
              ></path>
            </g>
            <g transform="translate(925.071 553.474)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-11.498 2.053-19.164-4.106-19.164-14.1v-40.792h-18.068V-14.1c0 19.986 13.825 33.949 37.232 31.074z"
                className="svg-elem-5"
              ></path>
            </g>
            <g transform="translate(982.727 533.625)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 12.183-7.803 20.67-17.385 20.67S-34.77 12.183-34.77 0c0-11.909 7.803-20.123 17.385-20.123S0-11.909 0 0m18.205.137c0-21.081-16.016-36.823-35.59-36.823-19.575 0-35.454 15.742-35.454 36.823 0 21.08 15.879 37.096 35.454 37.096 19.574 0 35.59-16.016 35.59-37.096"
                className="svg-elem-6"
              ></path>
            </g>
            <g transform="translate(1117.734 528.424)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-17.932-12.594-31.621-31.758-31.621-9.582 0-17.385 3.833-22.586 9.993-5.339-6.16-13.141-9.993-22.449-9.993-19.575 0-31.211 13.004-31.211 31.621v40.655h18.069V-.547c0-3.97 1.37-13.415 13.142-13.415 8.076 0 13.689 5.475 13.689 13.415v41.202h18.069V-.547c0-3.97 1.368-13.415 13.277-13.415 7.94 0 13.689 5.475 13.689 13.415v41.202H0z"
                className="svg-elem-7"
              ></path>
            </g>
            <g transform="translate(1142.638 506.522)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-6.159 6.297-9.582 15.058-9.582 24.776V87.06H8.487V24.502c0-10.266 6.16-16.426 16.289-16.426 10.267 0 16.427 6.16 16.427 16.426V87.06h18.069V24.776c0-9.718-3.422-18.479-9.582-24.776-6.296-6.16-15.057-9.582-24.914-9.582C15.058-9.582 6.297-6.16 0 0"
                className="svg-elem-8"
              ></path>
            </g>
            <g transform="translate(1246.891 553.885)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-9.993 0-18.069-8.897-18.069-19.849 0-11.224 8.076-20.259 18.069-20.259 9.992 0 18.068 8.761 18.068 20.259C18.068-8.487 9.992 0 0 0m2.464-56.808c-8.214 0-15.194 2.738-20.533 8.213v-38.054h-18.206v66.8C-36.275.411-21.354 16.974 0 16.974c21.217 0 36.138-16.563 36.138-36.823 0-20.396-15.058-36.959-33.674-36.959"
                className="svg-elem-9"
              ></path>
            </g>
            <g transform="translate(1304.256 586.926)">
              <path
                fill="#ffffff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c.969 0 1.937.5 1.937 1.907 0 1.406-.75 1.906-1.937 1.906h-3.657V0zm4.313-6.501H1.625l-2.25 4.345h-3.032v-4.313h-2.344V5.97h5.969c2.626 0 4.283-1.594 4.283-4.063 0-1.781-1.031-3.219-2.595-3.782zM-1.406 8.658c-5.001 0-9.065-4.125-9.065-9.189 0-5.094 4.064-9.22 9.065-9.22 5 0 9.063 4.126 9.063 9.22 0 5.064-4.063 9.189-9.063 9.189m0-20.503c-6.251 0-11.315 5.063-11.315 11.314 0 6.22 5.064 11.283 11.315 11.283 6.251 0 11.313-5.063 11.313-11.283 0-6.251-5.062-11.314-11.313-11.314"
                className="svg-elem-10"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
})``;

Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
