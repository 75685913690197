import { useEffect, useState, useContext } from 'react';
import { DashboardLayout } from '../layout/layout';
import { Box, Card, Container, Grid } from '@mui/material';
import FilterBar from '../components/dashboard/filter-bar';
import { KPIGrupalesLinea } from '../components/kpi/kpi-grupales-linea';
import KpiGrupalesTabla from '../components/kpi/kpi-grupales-tabla';
import { KPIIndividualesLineaCargaInspeccion } from '../components/kpi/kpi-individuales-linea-carga-inspeccion';
import { KPIIndividualesLineaEficiencia } from '../components/kpi/kpi-individuales-linea-eficiencia';
import KpiIndividualesCargaInspeccionTabla from '../components/kpi/kpi-individuales-carga-inspeccion-tabla';


function KPI() {
  const d = new Date(2022, 8, 1)
  const [filters, setFilter] = useState({ contratos: [], operadores: [], fecha_inicio: d, fecha_fin: new Date(), segmentacion: "mensual", area: [], clientes: [] })
  const [reload, setReload] = useState(true);

  const handleFiltersChange = (value) => {
    setFilter({
      ...filters,
      ...value
    })
  }

  const handleReload = () => {
    setReload(!reload)

  }


  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <FilterBar handleFiltersChange={handleFiltersChange} filters={filters} />
              </Grid>
              <Grid
                item
                lg={9}
                md={9}
                xl={9}
                xs={12}
              >
                <KPIGrupalesLinea filters={filters} handleReload={handleReload} />
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                xl={3}
                xs={12}
              >
                <KpiGrupalesTabla filters={filters} handleReload={handleReload} reload={reload} />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xl={8}
                xs={12}
              >
                <KPIIndividualesLineaCargaInspeccion filters={filters} handleReload={handleReload} />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
              >
                <KpiIndividualesCargaInspeccionTabla filters={filters} handleReload={handleReload} reload={reload} />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <KPIIndividualesLineaEficiencia filters={filters} handleReload={handleReload} />
              </Grid>
              
            </Grid>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  )
}

export default KPI;
