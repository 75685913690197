import { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { IconButton } from '@mui/material';
import { resolvePath } from '../../../../utils/path-resolvers';
import { format } from 'date-fns';
import {  certificadoEstado } from '../../../../services/certificados';
import { Checkbox } from '@mui/material';
import StyledChipUpdate from '../../../../styled-components/styled-chip-update';
import { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import StyledDatepickerUpdate from '../../../../styled-components/styled-datepicker-update';
import StyledTextfieldUpdate from '../../../../styled-components/styled-textfield-update';


function EnhancedTableRow({ handleConfirmDialogChange, handleNotifyChange, columns, certificado, selected, handleClick, index, handleReload, handleEdit, rol, ...props }) {
    const [open, setOpen] = useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(certificado._id);
    const labelId = `enhanced-table-checkbox-${index}`;
    



    return (
        <>

            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={certificado._id}
                selected={isItemSelected}
                style={{ paddingTop: "0px" }}
            >
                
                {columns.map((column) => {
                    return (
                        <Fragment key={column.id}>
                            {column.show &&
                                <TableCell
                                    key={column.id}
                                    align={column.numeric ? 'right' : 'left'}
                                    padding={column.disablePadding ? 'none' : 'normal'}
                                    style={{ backgroundColor: open ? "rgba(80, 72, 229, 0.12)" : "", fontSize: '8pt' }}
                                >
                                    <>
                                        {(column.type === "text" && column.id !== "hes") &&
                                            resolvePath(certificado, column.id)
                                        }
                                        {column.type === "number" &&
                                            `${new Intl.NumberFormat('de-DE').format(resolvePath(certificado, column.id)?.toFixed(2) || 0)}`

                                        }
                                        {column.type === "select" &&
                                            <StyledChipUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol} />
                                        }
                                        {column.type === "date" &&
                                            (resolvePath(certificado, column.id) ? format(new Date(resolvePath(certificado, column.id)), 'dd/MM/yyyy') : "-")
                                        }
                                        {column.type === "text_editable" &&
                                            <StyledTextfieldUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol} />
                                        }
                                        {column.type === "date_editable" &&
                                            <StyledDatepickerUpdate
                                                data={certificado}
                                                value={resolvePath(certificado, column.id)}
                                                edit={certificadoEstado}
                                                field={column.id}
                                                label={column.label}
                                                id={certificado._id}
                                                handleReload={handleReload}
                                                rol={rol}
                                            />
                                        }
                                    </>
                                </TableCell>
                            }
                        </Fragment>
                    )
                })}
                
            </TableRow >
          
        </>
    );
}


export default EnhancedTableRow