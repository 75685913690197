import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';

export default function AutocompleteSearch({ id, handleFiltersChange, getFunction, search, ...props }) {

    const [list, setList] = useState([])
    const [values, setValues] = useState(null)

    useEffect(() => {
        const getList = async () => {
            const res = await getFunction()
            setList(res.data)
        }
        getList()
    }, [])

    const handleChange = (newValue) => {
        //const selected = newValue.map((s) => s._id)
        handleFiltersChange([newValue?._id])
        setValues(newValue)
    };
    return (
        <Autocomplete
            options={list}
            value={values}
            getOptionLabel={(option) => `${option.nombre} ${option.apellido ? option.apellido : ""}`}
            renderOption={(props, option) => {
                return (

                    <Typography {...props} style={{ fontSize: "10px", padding:"2px 2px 2px 2px" }} key={option._id} >{`${option.nombre} ${option.apellido ? option.apellido : ""}`}</Typography>
                )
            }}
            size="small"
            onChange={(event, newValues) => {
                handleChange(newValues)
            }}
            renderInput={(params) => (
                <TextField {...params}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: 30,
                            fontSize: 10,
                        }
                    }} />
            )}
        />
    );
}
